import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useBrowserBack } from "../../hooks/useBrowserBack";
import { CSSProperties, useMemo } from "react";
import { Typography } from "@material-ui/core";
import GlobalModal from '../Modal/Modal';
import AppButton from "../AppButton/AppButton";
import classes from './BrowserBackModal.module.scss';

interface BrowserBackModalProp {
  businessPath: string | undefined;
  viewMode?: string;
  isMainPage?: boolean;
  needReload?: boolean;
}


const convertToViewMode = (orderType) => {
  if (!orderType) {
    return '';
  }

  if (["dine-in", "take-out", "pre-order", "delivery"].includes(orderType)) {
    return '';
  }

  return `/${orderType}`;
}

const BrowserBackModal = (props: BrowserBackModalProp) => {
  useBrowserBack();
  const { businessPath, isMainPage, needReload } = props;
  const { showBrowserBack, orderTypeFromTracking } = useTypedSelector((state) => state.appLocalStatus);
  const { setShowBrowserBackModal } = useActions();

  const stayOnPage = () => {
    setShowBrowserBackModal(false);
  };

  const leavePage = () => {
    if (needReload) {
      window.location.reload()
    }

    if (isMainPage) {
      return;
    }

    const viewMode = convertToViewMode(orderTypeFromTracking);
    const route = `/${businessPath}`;
    window.location.href = window.location.origin + route + viewMode;
  };

  const PopupContent = useMemo(() => {

    return <div>
      <Typography variant="h6" gutterBottom>
        If you leave, you won't be able to come back to this page
      </Typography>
    </div>
  }, []);

  const style: CSSProperties = { width: 140 };

  const Buttons = (
    <div className={classes.buttons}>

      <AppButton variant="outlined" isOutlined style={style} onClick={stayOnPage}>Cancel</AppButton>
      <AppButton style={style} onClick={leavePage}>OK</AppButton>

    </div>
  )

  return (<>
    <GlobalModal
      title="Are you sure?"
      open={showBrowserBack}
      handleClose={() => setShowBrowserBackModal(false)}
      onOk={() => setShowBrowserBackModal(false)}
      body={PopupContent}
      // closeOnBackdropClick
      buttons={Buttons}
    />
  </>
  );
};

export default BrowserBackModal;
