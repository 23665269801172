import { DestinationInfo, FinalOrderPayload, OrderingType, orderItemType, preItemType } from "../../Types/Types";
import { CheckoutFormStatus, DestinationStatus } from "../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface AppLocalStates {
  error: string | null;
  showBrowserBack: boolean;
  enableBrowserBack: boolean;
  itemAddedToCart?: preItemType | orderItemType;
  checkoutFormStatus: CheckoutFormStatus;
  destinationInfo: DestinationInfo;
  prevPrevOrderType?: OrderingType;
  prevOrderType?: OrderingType;
  orderTypeFromTracking?: FinalOrderPayload["orderType"];
}

const initialState: AppLocalStates = {
  error: null,
  showBrowserBack: false,
  enableBrowserBack: false,
  itemAddedToCart: undefined,
  prevPrevOrderType: undefined,
  checkoutFormStatus: CheckoutFormStatus.standby,
  destinationInfo: {
    destinationStatus: DestinationStatus.standby,
  },
};

const reducer = (
  state: AppLocalStates = initialState,
  action: Action
): AppLocalStates => {
  let newDestInfo = { ...state.destinationInfo };
  switch (action.type) {
    case ActionType.UPDATE_DESTINATION_INFO:
      newDestInfo = { ...action.payload };
      return { ...state, destinationInfo: newDestInfo };
    case ActionType.UPDATE_DESTINATION_STATUS:
      newDestInfo.destinationStatus = action.payload;
      return { ...state, destinationInfo: newDestInfo };
    case ActionType.SET_ADDED_CART_ITEM:
      const newCartItem = action.payload!;
      return { ...state, itemAddedToCart: newCartItem };
    case ActionType.SET_SHOW_BROWSERBACK_MODAL:
      return { ...state, showBrowserBack: action.payload };
    case ActionType.SET_ENABLE_BROWSERBACK_MODAL:
      return { ...state, enableBrowserBack: action.payload };
    case ActionType.UPDATE_CHECKOUT_FORM_STATUS:
      return { ...state, checkoutFormStatus: action.payload };
    case ActionType.UPDATE_STATUS_ERROR:
      return { ...state, error: action.payload };
    case ActionType.SET_ORDER_TYPE_FROM_TRACKING:
      return { ...state, orderTypeFromTracking: action.payload };
    case ActionType.SET_PREV_ORDER_TYPE:
      const newOrderType = action.payload;

      return {
        ...state,
        prevPrevOrderType: state.prevOrderType,
        prevOrderType: newOrderType,
      };
    default:
      return state;
  }
};

export default reducer;
