export enum ActionType {
  SET_APP_BOOTED_UP = "set_app_booted_up",
  APP_TURN_OFF = "app_turn_off",
  SET_BUSINESSINFO = "search_repositories_success",
  FETCH_BUSINESSINFO_SUCCESS = "search_repositories_success",
  FETCH_BUSINESSINFO_ERROR = "search_repositories_error",
  FETCH_FOODS_CATEGORIES_SUCCESS = "fetch_foods_categories_success",
  FETCH_FOODS_CATEGORIES_ERROR = "fetch_foods_categories_error",
  FETCH_PRODUCTS_SUCCESS = "fetch_products_success",
  FETCH_PRODUCTS_ERROR = "fetch_products_error",
  CHANGE_DISPLAY_MENU = "change_display_menu",
  ADD_ITEM = "add_item",
  PRE_ITEM = "pre_item",
  SET_COUNT_ITEM = "set_count_item",
  INCREMENT_ITEM = "increment_item",
  DECREMENT_ITEM = "decrement_item",
  REMOVE_ITEM = "remove_item",
  SELECT_TIP = "select_tip",
  SET_INFO_TO_FINAL_ORDER = "set_info_to_final_order",
  SET_COMPLETE_FINAL_ORDER = "set_complete_final_order",
  SET_DISCOUNTS_TO_FINAL_ORDER = "set_discounts_to_final_order",
  SET_MOBILE_DISCOUNTS_TO_FINAL_ORDER = "set_mobile_discounts_to_final_order",
  UPDATE_CUSTOMIZE = "update_customize",
  CHANGE_ORDER = "change_order",
  EMPTY_CART = "empty_cart",
  SET_SPECIAL_NOTES = 'set_special_notes',
  SET_ORDER_TYPE = 'set_order_type',
  SET_PREV_ORDER_TYPE = 'SET_PREV_ORDER_TYPE',
  SET_USER_NAME = 'set_user_name',
  SET_USER_NUMBER = 'set_user_number',
  SET_TIP_PERCENTAGE = 'set_tip_percentage',
  SET_TIME = 'set_time',
  SEND_ORDER_SUCCESS = 'send_order_success',
  SEND_ORDER_ERROR = 'send_order_error',
  UPDATE_DESTINATION_STATUS = 'UPDATE_DESTINATION_STATUS',
  UPDATE_STATUS_ERROR = 'update_status_error',
  SEND_RECEIPT_SUCCESS = 'send_receipt_success',
  SEND_RECEIPT_ERROR = 'send_receipt_error',
  SIGN_IN_SUCCESS = 'sign_in_success',
  SIGN_IN_ERROR = 'sign_in_error',
  SIGN_UP_SUCCESS = 'sign_up_success',
  SIGN_UP_ERROR = 'sign_up_error',
  SIGN_OUT = 'sign_out',
  SET_ACCOUNT_ERROR = 'set_error',
  RESET_PASSWORD_SENT = 'forgot_password',
  SET_ORDER_USER_ID = "set_order_user_id",
  CLEAR_ACCOUNT_ERRORS = "clear_account_errors",
  EXTERNAL_SIGN_IN_ERROR = "external_sign_in_error",
  ADD_OVERALL_DISCOUNT = "add_overall_discount",
  SET_CREDIT_DISCOUNT = "set_credit_discount",
  CLEAR_CREDIT_DISCOUNT = "clear_credit_discount",
  SET_PAPER_BAG_COUNT = "set_paper_bag_count",
  SET_PAPER_BAG_FEE = "set_paper_bag_fee",
  SET_PREORDER_DATETIME = "set_preorder_datetime",
  SET_SMS_NOTIFICATION_REQUESTED = "SET_SMS_SENDING_ON_CONFIRMED_AND_READY",
  CLEAR_ORDER_USER_ID = "CLEAR_ORDER_USER_ID",
  SET_ORDER_MODE = "SET_ORDER_MODE",
  SET_CATERING_DATE_TIME_SUCCESS = "SET_CATERING_DATE_TIME_SUCCESS",
  SET_DELIVERY_INFO = "SET_DELIVERY_INFO",

  UPDATE_CHECKOUT_FORM_STATUS = "UPDATE_CHECKOUT_FORM_STATUS",
  UPDATE_DESTINATION_INFO = "UPDATE_DESTINATION_INFO",
  CLEAR_DISPLAY_MENU = "CLEAR_DISPLAY_MENU",
  SET_ORDER_TYPE_FROM_TRACKING = "SET_ORDER_TYPE_FROM_TRACKING",
  SET_SHOW_BROWSERBACK_MODAL = "SET_SHOW_BROWSERBACK_MODAL",
  SET_ENABLE_BROWSERBACK_MODAL = "SET_ENABLE_BROWSERBACK_MODAL",
  SET_ADDED_CART_ITEM = "SET_ADDED_CART_ITEM",
}
