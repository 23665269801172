import classes from "./Checkout.module.scss";
import { useParams } from "react-router";
import { OrderMode, StoreOrderingState, deliveryInfoCookieKey, orderTypeMap, userPhoneCookieKey } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useActions } from "../../hooks/useActions";
import { useMemo } from "react";
import { isNumber } from "../../common/price/price";
import { setCookie } from "../../common/cookies";
import { validatePhone } from "../../common/phoneUtils";
import { getOrderMode } from "../../common/app";
import { CheckoutFormStatus } from "../../Types/Types";
import { useDeliveryAddress } from "./hooks/useDeliveryAddress";
import AppButton from "../AppButton/AppButton";

interface CheckoutFooterProps {
    handleNext: Function;
}


const CheckoutFooter = (props: CheckoutFooterProps) => {
    const { handleNext } = props;
    const { setInfoToFinalOrder, updateCheckoutFormStatus } = useActions();
    const { ValidateDeliveryAddress } = useDeliveryAddress();

    const { viewMode } = useParams<IPublicProfileRouteParams>();
    const finalOrder = useTypedSelector((state) => state.order.finalOrder);

    const { businessInfo } = useTypedSelector((state) => state.businessInfo);

    const isOrderTypePreOrder = finalOrder.orderType === orderTypeMap.preorder;
    const appViewMode = getOrderMode(viewMode);
    const isCateringViewMode = appViewMode === OrderMode.catering;
    const isPreOrderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
    const isTableViewMode = isPreOrderOnly ? false : isNumber(viewMode);
    const isDeliveryOrder = finalOrder.orderType === "delivery";


    const onSubmitOrderDetails = () => {
        const { userPhoneNumber, customerName } = finalOrder;

        const hasErrors = validateForm();
        if (!isTableViewMode && hasErrors) return;

        if (isDeliveryOrder && finalOrder.deliveryAddress) {
            setCookie(deliveryInfoCookieKey, JSON.stringify(finalOrder.deliveryAddress));
        }

        setCookie(userPhoneCookieKey, userPhoneNumber);
        setInfoToFinalOrder(businessInfo, isTableViewMode);
        handleNext();
    };

    const validateForm = () => {
        const { userPhoneNumber, deliveryAddress, customerName, requestedPrepDateTime } = finalOrder;
        let hasErrors = false;
        if (!validateName(customerName)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }

        if (!validateUserPhone(userPhoneNumber)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }

        if (!ValidateDeliveryAddress(deliveryAddress)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }

        if (!validateDateTime(requestedPrepDateTime)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }
        return hasErrors;
    };

    const isCustomerContactRequired = useMemo(() =>
        businessInfo?.customerContactInfo === "required"
        , [businessInfo?.customerContactInfo]);

    const validateDateTime = (requestedPrepDateTime?: number) => {
        const isDateTimeOrderTypes = isOrderTypePreOrder || isCateringViewMode;
        if (!isDateTimeOrderTypes) return true;
        return !!requestedPrepDateTime;
    };

    const validateName = (customerName?: string) => {
        if (!businessInfo?.customerNameRequired) return true;
        return !!customerName;
    };

    const validateUserPhone = (number: string) => {
        if (number.length === 0 && isCustomerContactRequired === false) {
            return true;
        }
        return validatePhone(number);
    };

    return <>
        <AppButton onClick={onSubmitOrderDetails}>
            View order summary
        </AppButton>
    </>
}


export default CheckoutFooter;