import { useEffect, useCallback, useState } from 'react';
import { useTypedSelector } from './useTypedSelector';
import { useActions } from './useActions';

const history = window.history;

const useExitPrompt = () => {
  const { showBrowserBack, enableBrowserBack } = useTypedSelector((state) => state.appLocalStatus);
  const { setShowBrowserBackModal } = useActions();

  const onBefore = useCallback((event) => {
    console.log(event);

    const isPopstateEvent = event.type === "popstate";
    if (enableBrowserBack) {
      const e = event || window.event;
      e.preventDefault();
      if (isPopstateEvent) {
        const selected = window.confirm("Changes you made may not be saved.")
        if (!selected) {
          history.go(1);
          return;
        }
      } else {
        if (e) e.returnValue = "any";
        return "any";
      }
    }



    setShowBrowserBackModal(false);
  }, [enableBrowserBack]);

  const initBeforeUnLoad = useCallback(() => {
    if (window.onbeforeunload !== onBefore) {
      window.onbeforeunload = onBefore;
    }

    if (window.onpopstate !== onBefore) {
      window.onpopstate = onBefore;
    }
  }, [onBefore, window]);

  useEffect(() => {
    initBeforeUnLoad();
  }, [initBeforeUnLoad, enableBrowserBack]);

}

export default useExitPrompt;