import classes from "./FoodsCategoryTabs.module.scss";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { OrderMode } from "../../constants";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useParams } from "react-router";
import { getOrderMode } from "../../common/app";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickOverwrite.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { appendBaseUrlToImage } from "../../common/image";
import '@mui/system';
import '@mui/material/styles';
import React from "react";
import { MenuCategory } from "../../Types/Types";

interface FoodsCategoryTabProps {
  name: string;
  onTabChange: Function;
  categoryId: number;
  iconUrl: string;
  index: number;
}

function checkImage(url, successCallback, errorCallback) {
  const img = new Image();

  img.onload = function () {
    // Image is valid
    successCallback();
  };

  img.onerror = function () {
    // Image is faulty
    errorCallback();
  };

  img.src = url;
}

const placeholderImage = (
  <div style={{ width: '30px', height: '30px' }}>
  </div>
);


const FoodsCategoryTab: React.FC<FoodsCategoryTabProps> = (props) => {
  const { name, categoryId, onTabChange, iconUrl, index } = props;
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { changeDisplayMenu } = useActions();
  const [isValidImage, setIsValidImage] = useState(false);

  useEffect(() => {
    // Check if the image is valid
    checkImage(iconUrl,
      () => setIsValidImage(true),
      () => setIsValidImage(false)
    );
  }, [iconUrl]);

  const changeMenu = useCallback(() => {
    onTabChange();

    if (window.scrollY < 1) {
      changeDisplayMenu(categoryId);
      return;
    }

  }, [categoryId]);

  const IconElem = useMemo(() => {
    if (isValidImage) {
      return <img
        src={iconUrl}
        alt={name}
        onClick={() => {
          changeMenu();
        }}
        className={classes.tab}
        style={{ width: '30px', height: '30px' }}
      />
    } else {
      return placeholderImage;
    }

  }, [isValidImage])

  return (
    <Tab
      icon={IconElem}
      iconPosition="top"
      style={{ opacity: "1.0" }}
      value={index}
      label={<span style={{
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        textAlign: 'center',
        textTransform: 'capitalize'
      }}>
        {name}
      </span>
      }
      onClick={() => {
        changeMenu();
      }}
    />
  );
};

type FoodsCategoryTabsProps = {
  productsRefsByCategoryIds: { [k: string]: RefObject<HTMLDivElement> };
}


const FoodsCategoryTabs = (props: FoodsCategoryTabsProps) => {
  const { productsRefsByCategoryIds } = props;
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isKioskViewMode = appViewMode === OrderMode.kiosk;
  const { foodsCategories, productsInfo, businessInfo } = useTypedSelector((state) => state.businessInfo);
  const wrapperRef = useRef(null);
  const [showNext, setShowNext] = useState<boolean>(true);
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [isAfterSwipe, setIsAfterSwipe] = useState<boolean>(false);
  const [isTabClicked, setIsTabClicked] = useState<boolean>(false);
  const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout>();
  const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState(0);


  const filteredProducts = Object.entries(productsInfo ?? {})
    .filter(([key, menuItems]) => menuItems.length > 0);

  const categoriesWithFood = useMemo(() => {
    if (filteredProducts.length === 0 || !foodsCategories) return foodsCategories;

    return foodsCategories.filter(category => filteredProducts.find(prod => category.id === +prod[0]))
  }, [foodsCategories, filteredProducts]);

  const indexByCategoryIds: { [k: string]: number } = useMemo(() => {
    if (!categoriesWithFood) return {};
    return Object.fromEntries(categoriesWithFood.map((cat, index) => [cat.id, index]))
  }, [categoriesWithFood]);

  const entryBoundaries = useMemo(() => {
    if (!productsRefsByCategoryIds) return [];
    const entries = Object.entries(productsRefsByCategoryIds);

    const boundaries = entries.map((e) => {
      const ref = e[1];
      const offsetTop = ref.current?.offsetTop;
      const rect = () => ref.current?.getBoundingClientRect();

      return { offsetTop, rect, entry: e }
    })

    return boundaries;
  }, [productsRefsByCategoryIds]);

  const rangeOffset = 200;
  const handleScroll = useCallback((event) => {
    if (isTabClicked) return;

    const yPos = window.scrollY;

    let entry = entryBoundaries.find((e) => {

      const top = yPos + (e.rect()?.top ?? 0) - rangeOffset;
      const bottom = yPos + (e.rect()?.bottom ?? 0) - rangeOffset;
      if (!top || !bottom) return false;

      let isFound = top <= yPos && yPos <= bottom;
      return isFound;
    });

    if (!entry) return;
    const categoryId = entry?.entry[0];
    const index = indexByCategoryIds[categoryId];

    if (index === undefined) return;
    setCurrentSelectedTabIndex(index);
  }, [currentSelectedTabIndex, productsRefsByCategoryIds, isTabClicked]);

  const onScrollEnd = () => setIsTabClicked(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    // window.addEventListener('scrollend', onScrollEnd);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      // window.removeEventListener('scrollend', onScrollEnd);
    };
  }, [handleScroll]);

  const onTabChange = useCallback((index, categoryId: number) => {
    const ref = productsRefsByCategoryIds[categoryId];
    const elemNode = ref.current;
    setCurrentSelectedTabIndex(index);

    if (elemNode) {
      setIsTabClicked(true);
      window.scroll({ top: elemNode.offsetTop - 156, behavior: 'smooth' });
      
      clearTimeout(scrollTimeout);
      const newTimeout = setTimeout(function () {
        // Your code to handle scroll end here
        onScrollEnd();
        console.log("Scroll ended");
      }, 1000);
      setScrollTimeout(newTimeout);
    }

  }, [productsRefsByCategoryIds, scrollTimeout]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue, 0);
  };

  return (
    <Box>
      <Tabs
        value={currentSelectedTabIndex}
        TabIndicatorProps={{ style: { backgroundColor: businessInfo?.theme.color, height: "4px" } }}
        onChange={handleChange}
        variant="scrollable"
      >
        {categoriesWithFood &&
          categoriesWithFood.map((category: MenuCategory, index) => {
            return (
              // <Tab key={category.id}>
              <FoodsCategoryTab
                categoryId={category.id}
                key={category.id}
                name={category.name}
                onTabChange={() => onTabChange(index, category.id)}
                iconUrl={appendBaseUrlToImage(category.defaultProductImage)}
                index={index}
              />
              // </Tab>
            );
          })}
      </Tabs>
    </Box>
  );

};

export default FoodsCategoryTabs;
