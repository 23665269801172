import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlickOverwrite.scss";
// Import css files
import classes from "./OrderList.module.scss";
import CustomizeModal from "../CustomizeModal/CustomizeModal";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { orderItemType } from "../../Types/Types";
import { appendBaseUrlToImage } from "../../common/image";
import { OptionType } from "../../constants";
import ReviewOrderPopup from "../ReviewOrder/ReviewOrder";
import CheckoutSheet from "../UI/ModalSheet/CheckoutSheet";

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 1500,
  },
  content: {
    padding: "0",
    border: "none",
    backgroundColor: "white",
  },
};

const OrderList: React.FC = () => {
  const orders = useTypedSelector((state) => state.order);
  const { businessInfo, productsInfo } = useTypedSelector(
    (state) => state.businessInfo
  );
  const { setCountItem } = useActions();
  const [showCustomize, setShowCustomize] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const sliderRef = useRef<any>();
  const [isReviewOrderOpen, setIsReviewOrderOpen] = useState(false);
  const { addCustomization, setInfoToFinalOrder, addItem, removeItem } =
    useActions();
  const { isSignedIn } = useTypedSelector((state) => state.account);
  const { finalOrderItems } = useTypedSelector(
    (state) => state.order.finalOrder
  );
  const [showCheckout, setShowCheckout] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(orders.order.length - 1);
    }
  }, [orders.order.length]);

  const getStyle = () => {
    if (!businessInfo) return {};
    return {
      color: businessInfo.theme.color,
      width: "100%",
    };
  };

  const setCount = (index: number, count: number) => {
    setCountItem(index, count);
  };

  const handleCustomize = (i: number) => {
    setIsReviewOrderOpen(false);
    setShowCustomize(true);
    setSelectedIndex(i);
  };

  const getStoreProductAndOption = (optionId) => {
    if (!productsInfo) return;

    const products = Object.values(productsInfo).flatMap(
      (products) => products
    );
    const storeProduct = products.find((prod) => {
      return prod.options.find((option) => {
        return option.id === optionId;
      });
    });
    const storeOption = storeProduct?.options.find((option) => {
      return option.id === optionId;
    });

    return { storeProduct, storeOption };
  };

  const getImgUri = (order: orderItemType) => {
    let imgPath = order.productImage ?? order.image;

    if (!imgPath && productsInfo) {
      const productOption = getStoreProductAndOption(order.productOptionId);
      imgPath =
        productOption?.storeOption?.optionImage ??
        productOption?.storeProduct?.image ??
        "";
    }

    return appendBaseUrlToImage(imgPath);
  };
  const handleReviewOrderClick = () => {
    setIsReviewOrderOpen(true);
  };

  const handleCloseReviewOrder = () => {
    setIsReviewOrderOpen(false);
  };
  const updateOrderQuantity = (index, newCount) => {
    setCountItem(index, newCount);
    setInfoToFinalOrder();
  };
  const handleDeleteItem = (item) => {
    removeItem(item);
    setInfoToFinalOrder();
    if (orders.order.length === 0) {
      handleCloseReviewOrder();
    }
  };
  const openCheckout = () => {
    let isSoldOut = false;
    for (const x of finalOrderItems) {
      const productOption = getStoreProductAndOption(x);

      if (productOption?.storeOption && productOption.storeOption.soldout) {
        isSoldOut = true;
        break;
      }
    }
    if (isSoldOut) {
      setErrorMsg("Please remove the soldout items");
      return;
    } else {
      setIsReviewOrderOpen(false);
      setErrorMsg(undefined);
      const containsGiftCardsInCart =
        finalOrderItems.find((item) => item.type === OptionType.giftCard) !==
        undefined;

      const needSignedInUser = containsGiftCardsInCart && !isSignedIn;
      setShowCheckout(needSignedInUser === false);
    }
  };

  const TitleText = useMemo(() => {
    const itemCount = orders.order.reduce(
      (total, order) => total + order.count,
      0
    );
    const pluralSuffix = itemCount > 1 ? "items" : "item";
    const subtotal = (orders.finalOrder.subTotal / 100).toFixed(2);

    return `Review & Customize ${itemCount} ${pluralSuffix} ($${subtotal})`;
  }, [orders]);

  const renderOrder = () => {
    return (
      <div className={classes.cartcontainer}>
        <button
          className={classes.revieworderbtn}
          onClick={handleReviewOrderClick}
          style={{ backgroundColor: businessInfo?.theme.color }}
        >
          {TitleText}
        </button>

        <ReviewOrderPopup
          open={isReviewOrderOpen}
          onClose={handleCloseReviewOrder}
          updateOrderQuantity={updateOrderQuantity}
          handleDeleteItem={handleDeleteItem}
          businessInfo={businessInfo}
          openCheckout={openCheckout}
          showCustomize={handleCustomize}
          getImgUri={getImgUri}
          getStoreProductAndOption={getStoreProductAndOption}
          errorMsg={errorMsg}
        />

        <CheckoutSheet setShowCheckout={setShowCheckout} show={showCheckout} />
      </div>
    );
  };

  const setCustomize = (value: boolean) => {
    setShowCustomize(value);
    setIsReviewOrderOpen(true);
  };

  const CustomizePopup = (
    <>
      {showCustomize && (
        <CustomizeModal
          index={selectedIndex}
          isOpen={showCustomize}
          style={modalStyle}
          setShowCustomize={setCustomize}
          type="cart"
          setCount={setCount}
        />
      )}
    </>
  );

  return (
    <div className={classes.slider} style={getStyle()}>
      {CustomizePopup}

      <div className={classes.cartcontainer}>
        <button
          className={classes.revieworderbtn}
          onClick={handleReviewOrderClick}
          style={{ backgroundColor: businessInfo?.theme.color }}
        >
          {TitleText}
        </button>

        <ReviewOrderPopup
          open={isReviewOrderOpen}
          onClose={handleCloseReviewOrder}
          updateOrderQuantity={updateOrderQuantity}
          handleDeleteItem={handleDeleteItem}
          businessInfo={businessInfo}
          openCheckout={openCheckout}
          showCustomize={handleCustomize}
          getImgUri={getImgUri}
          getStoreProductAndOption={getStoreProductAndOption}
          errorMsg={errorMsg}
        />

        <CheckoutSheet setShowCheckout={setShowCheckout} show={showCheckout} />
      </div>
    </div>
  );
};

export default OrderList;
