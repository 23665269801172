import { combineReducers } from "redux";
import businessInfoReducer from "./businessInfoReducer";
import displayedMenuReducer from "./displayedMenuReducer";
import orderReducer from "./orderReducer";
import trackReducer from './trackReducer';
import appLocalStatesReducer from './appLocalStatesReducer';
import receiptReducer from './receiptReducer';
import accountReducer from './accountReducer';


const reducers = combineReducers({
  businessInfo: businessInfoReducer,
  displayedMenuCategory: displayedMenuReducer,
  order: orderReducer,
  trackInfo : trackReducer,
  appLocalStatus : appLocalStatesReducer,
  receiptSuccess : receiptReducer,
  account : accountReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
