import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { IPublicProfileRouteParams } from '../Types/RouteTypes';
import { useTypedSelector } from './useTypedSelector';
import { useActions } from './useActions';

const originalPopstate = window.onpopstate;
export const useBrowserBack = () => {
    const { businessName, viewMode } = useParams<IPublicProfileRouteParams>();
    const { setShowBrowserBackModal } = useActions();
    const { enableBrowserBack, showBrowserBack } = useTypedSelector(state => state.appLocalStatus);
    const preItem = useTypedSelector(state => state.order.preItems);
    const orders = useTypedSelector((state) => state.order);

    const urlToPush = useMemo(()=> {
        return `/${businessName}${viewMode ? "/" + viewMode : ""}`
    }, [businessName, viewMode]);

    const handleBrowserBack = useCallback(() => {
        window.history.pushState(null, "", urlToPush);
        setShowBrowserBackModal(true);
    }, [window, urlToPush, setShowBrowserBackModal]);

    useEffect(() => {
        if (!enableBrowserBack) {
            window.onpopstate = originalPopstate;
        }
    }, [enableBrowserBack]);

    const hasPreItem = preItem && preItem.productOptionId !== 0;
    const hasCartItems = hasPreItem || orders.order.length > 0;
    // useEffect(() => {
    //     if (!businessName) return;

    //     if (enableBrowserBack && hasCartItems) {
    //         window.history.pushState(null, "", urlToPush);
    //         window.onpopstate = handleBrowserBack;
    //     }
        
    //     return () => {
    //         window.onpopstate = originalPopstate;
    //     };
    // }, [handleBrowserBack, businessName, hasCartItems, urlToPush, enableBrowserBack]);

    return;
};
