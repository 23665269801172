import classes from "./CateringDateTimeDisplay.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import StyledButton from "../StyledComponent/StyledButton";
import { useHistory, useParams } from "react-router";
import { useBizTheme } from "../../hooks/useBizTheme";
import { OrderMode } from "../../constants";
import { getOrderMode } from "../../common/app";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { Typography } from "@material-ui/core";
import { CSSProperties } from "react";

interface CateringDateTimeDisplayProps {
  hideEditButton?: boolean;
  stickPosition?: boolean;
}

const CateringDateTimeDisplay = (props: CateringDateTimeDisplayProps) => {
  const { stickPosition = false, hideEditButton = false } = props;
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isCateringViewMode = appViewMode === OrderMode.catering;

  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const finalOrder = useTypedSelector((state) => state.order.finalOrder);
  const history = useHistory();
  const theme = useBizTheme();

  const prepDateMilliSeconds = finalOrder.requestedPrepDateTime;
  if (!prepDateMilliSeconds || !isCateringViewMode) return <></>;

  const prepDate = new Date(prepDateMilliSeconds);
  const serviceUserId = businessInfo?.serviceProviderUserId;

  const containerStyle: CSSProperties = stickPosition ? {
    position: "absolute",
    margin: "30px 0",
  } : {
    position: "initial",
    marginTop: "20px",
    marginBottom: 0,
  }

  return <>
    <div className={classes.container} style={containerStyle}>

      <Typography>Selected Date:</Typography>

      <div className={classes.dateTime}>
        {prepDate.toLocaleString("en-CA", {
          day: "numeric",
          weekday: "short",
          year: "numeric",
          month: "short",
          hour: "numeric",
          minute: "numeric",
        })}
      </div>

      {!hideEditButton ?
        <StyledButton
          className={classes.button}
          onClick={() => {
            if (!serviceUserId) {
              console.error("cannot navigate no serviceUserId in businessjson")
              return;
            }
            history.replace(`/${serviceUserId}/catering`)

          }}
          style={{ backgroundColor: theme?.color }}>
          Change
        </StyledButton>
        : <></>}
    </div>
  </>
}

export default CateringDateTimeDisplay;