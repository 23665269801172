import classes from "./PhoneInput.module.scss";
import { useState } from "react";
import { alternatePhone, userPhoneCookieKey } from "../../constants";
import { getFormattedPhoneNumber, normalizeFormattedPhoneNumber, validatePhone } from "../../common/phoneUtils";
import { submitOrder } from "../../api/order";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { setCookie } from "../../common/cookies";
import Stack from "@mui/material/Stack";
import InputWithIcons from "../Account/InputWithIcon";
import IconButton from '@mui/material/IconButton';
import ForwardIcon from "../../assets/icons/forwardIcon";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
const waitTime = 1000;

interface PhoneInputProps {
  getStatus: Function;
  orderNumber: number;
}

const PhoneInput = (props: PhoneInputProps) => {
  const { getStatus, orderNumber } = props;
  const finalOrder = useTypedSelector((state) => state.order.finalOrder);
  const { setUserNumber } = useActions();
  const [hasPhoneError, showPhoneError] = useState(false);
  const [phoneNumber, setPhoneInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onInputChange = (newValue) => {
    const currentValue = newValue as string;
    showPhoneError(false);
    const phoneNum = normalizeFormattedPhoneNumber(currentValue);
    setPhoneInputValue(phoneNum);
  }

  const onDone = () => {
    setIsLoading(false);
    setPhoneInputValue("");
  }


  const handlePhoneSubmit = async () => {
    const isValid = validatePhone(phoneNumber);
    showPhoneError(!isValid);
    if (isValid) {
      finalOrder.userPhoneNumber = phoneNumber;
      finalOrder.orderNumber = orderNumber;
      setIsLoading(true);

      const res = await submitOrder(finalOrder);
      if (res && res.success) {
        setUserNumber(phoneNumber);
        setCookie(userPhoneCookieKey, phoneNumber);
        setTimeout(() => {
          getStatus();
          onDone();
        }, waitTime);
      } else {
        onDone();
      }

    }
  }

  return (
    <div className={classes.container}>
      <Box sx={{
        margin:'1rem 0'
      }}>
          <Typography className={classes.label}>{alternatePhone.title}</Typography>
      </Box>
      <Stack direction="row"  justifyContent='space-between' sx={{
          gap: '1rem', margin:'0 -8px'
        }}>
          
          <InputWithIcons
          value={getFormattedPhoneNumber(phoneNumber)}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder="(555) 555-5555"
          Icon={LocalPhoneIcon}
          label="Enter phone number"
          type="text"
          maxLength={10}
        />
        <IconButton size="small" sx={{
          backgroundColor:'primary.main',
          color:'white',
          borderRadius:'10px',
          padding: '0 1rem',
          height:'3.7rem',
          '&:hover':{
            backgroundColor:'primary.main'
          },
          '&:disabled':{
            backgroundColor:'primary.light'
          }
        }}
        disabled={hasPhoneError || isLoading}
        onClick={handlePhoneSubmit}
        >
            <ForwardIcon />
        </IconButton>
        </Stack>

      {hasPhoneError && <div className={classes.inputError}>
        Please enter a valid phone number!
      </div>}

    </div>
  )
}

export default PhoneInput;