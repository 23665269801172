import CircularProgress from '@material-ui/core/CircularProgress';
import classes from './Loader.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';


const Loader = ({ handlePath }: any)=> {
  	const { businessInfo } = useTypedSelector((state) => state.businessInfo);	

	const getStyle = () => {
		if (!businessInfo) return {};
		return {
		  color: businessInfo.theme.color,
		};
	};

	
	return (
		<div className={classes.loader}>
			<CircularProgress style={getStyle()}/>
		</div>
	)
}

export default Loader
