import classes from "./CateringSelection.module.scss";
import StyledButton from "../StyledComponent/StyledButton";
import DropDownCombo from "./DropDownCombo";
import axios from "axios";
import { useEffect, useMemo, useCallback, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { currentTimeOptions, getPreOrderDates, padTimePart } from "../../common/date";
import { StoreOrderingState } from "../../constants";
import { useBizTheme } from "../../hooks/useBizTheme";
import { KeyboardDatePicker } from "@material-ui/pickers/DatePicker/DatePicker";
import { DateTimeOption } from "../../Types/Types";
import { baseUrl } from "../../serverConfig";
import { useHistory } from "react-router";
import { BizLocation } from "../../pages/locationSelector/LocationSelectorPage";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { fetchProducts } from "../../state/action-creators";

interface CateringSelectionProps {
  addresses: BizLocation[]
}

export const CateringSelection = (props: CateringSelectionProps) => {
  const { addresses } = props;
  const { foodsCategories, businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { setPreOrderDateTime, storeCateringProducts,
    storeProductsAndCategories, setAppBooted, setBizInfo } = useActions();
  const finalOrder = useTypedSelector((state) => state.order.finalOrder);
  const theme = useBizTheme();
  const history = useHistory();
  const [selectedLocationIndex, setSelectedLocation] = useState<number>(addresses.length === 1 ? 0 : -1);
  const [errorMsg, setErrorMsg] = useState<string>();
  const isPreOrderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;



  const defaultMaterialTheme = createTheme({
    palette: {
      primary: {
        main: theme?.color ?? "black",
      },
    },
  });

  const serviceProviderUserId = businessInfo?.serviceProviderUserId;
  const businessId = businessInfo?.businessId;
  
  useEffect(() => {
    (async () => {
      if (!foodsCategories || foodsCategories?.length === 0) {
        const { data } = await axios.get(`${baseUrl}/rest/productCategory/${serviceProviderUserId}`);
        storeProductsAndCategories(data);
      }
    })()
  }, [foodsCategories, serviceProviderUserId]);

  const minDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + (businessInfo?.preOrderPrepMinDays ?? 0))
    return date;
  }, [businessInfo?.preOrderPrepMinDays]);

  const maxDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + (businessInfo?.preOrderPrepMaxDays ?? 0))
    return date;
  }, [businessInfo?.preOrderPrepMaxDays]);

  const prepDate = finalOrder.requestedPrepDateTime;

  const dateOptions = getPreOrderDates(businessInfo?.workingHours);
  const dateTimeOptions = currentTimeOptions(dateOptions, isPreOrderOnly);
  const getSelectedPrepDate = (prepDate?: number) => {
    if (!prepDate) return undefined;

    const selectedPrepDate = new Date(prepDate);
    return selectedPrepDate;
  };

  const selectedPrepDate = getSelectedPrepDate(finalOrder.requestedPrepDateTime);
  const selectedDateOptionIndex = dateTimeOptions.findIndex((dateTimeOption) => {
    if (selectedPrepDate) {
      return dateTimeOption.date.getDate() === selectedPrepDate.getDate();
    } else {
      return false;
    }
  });

  const selectedDateTimeOption = dateTimeOptions[selectedDateOptionIndex] ?? dateTimeOptions[0];
  const timeOptions = selectedDateTimeOption?.timeOptions ?? [];

  const selectedTimeOptionIndex = useMemo(() => {
    return timeOptions.findIndex((timeOption) => {
      if (selectedPrepDate) {
        return (
          timeOption.minute === selectedPrepDate.getMinutes() &&
          timeOption.hour === selectedPrepDate.getHours()
        );
      } else {
        return false;
      }
    });
  }, [timeOptions, selectedPrepDate]);

  const validateForm = useCallback(() => {
    if ((selectedTimeOptionIndex ?? -1) === -1) return false;
    if (addresses.length !== 1 && selectedLocationIndex === -1) {
      return false;
    }

    return true;
  }, [selectedLocationIndex, selectedTimeOptionIndex]);

  const handleDateChange = (date) => {
    const matchByDate = (value: DateTimeOption, index: number, obj: DateTimeOption[]) => {
      return value.date.getDay() === date.getDay();
    }

    const dateOption = dateTimeOptions.find(matchByDate);
    const firstAvailableTimeOption = dateOption?.timeOptions[0];
    if (!firstAvailableTimeOption) {
      setPreOrderDateTime(undefined);
      return;
    }

    date.setHours(0);
    date.setMinutes(0);

    setPreOrderDateTime(date.getTime());
    setErrorMsg("");
  }

  const fetchProductsByDateTime = useCallback(async () => {
    setErrorMsg("");
    const serviceProviderUserId = businessInfo?.serviceProviderUserId;
    if (!serviceProviderUserId) return;
    if (!validateForm()) {
      setErrorMsg("Both Time and Location are required!")
      return;
    }

    const productsData = await fetchProducts(serviceProviderUserId, businessId, 'catering', prepDate);
    if (!!productsData) {
      storeCateringProducts(productsData);
    }
    const locationBizJson = addresses[selectedLocationIndex]?.json;
    if (locationBizJson) {
      setBizInfo(locationBizJson)
      setAppBooted(true);
      history.push(`/${locationBizJson?.routingPath}/catering`);
    } else {
      console.error(`No bizJson found at index ${selectedLocationIndex}, in the addresses lists.`);
    }

  }, [businessInfo?.serviceProviderUserId, prepDate, selectedLocationIndex, validateForm])

  const titleStyle: React.CSSProperties = { backgroundColor: theme?.color };

  return (
    <div className={classes.container}>
      <section className={classes.title} style={titleStyle}>
        <span className={classes.label}>Select Date and Time for Catering</span>
      </section>

      <section className={classes.body}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <KeyboardDatePicker
            autoOk
            variant="static"
            inputVariant="outlined"
            label="Date"
            disablePast
            format="MM/dd/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            defaultValue={null}
            value={selectedPrepDate}
            style={{ backgroundColor: theme?.color }}
            InputAdornmentProps={{ position: "start" }}
            onChange={date => handleDateChange(date)}
            shouldDisableDate={(date) => {
              if (!date) return true;
              const selectedDay = date.getDay();
              const matchByDate = (value: DateTimeOption, index: number, obj: DateTimeOption[]) => {
                const dateDay = value.date.getDay();
                return dateDay === selectedDay;
              }

              const dateOption = dateTimeOptions.find(matchByDate);
              return !dateOption;
            }}
          />
        </ThemeProvider>

        <section className={classes.dropdownsSection}>

          <DropDownCombo
            label="Pickup Time"
            name="time"
            id="time"
            disabled={false}
            defaultValue={-1}
            value={selectedTimeOptionIndex}
            hasError={!!errorMsg}
            required
            onChange={(e) => {
              if (!selectedPrepDate) return;
              const optionIndex = e.target.value;
              const timeOption = timeOptions[+optionIndex];
              if (!timeOption) {
                setPreOrderDateTime(undefined);
                return;
              }
              selectedPrepDate.setHours(timeOption.hour);
              selectedPrepDate.setMinutes(timeOption.minute);
              setPreOrderDateTime(selectedPrepDate.getTime());
              setErrorMsg("");
            }}
          >
            <option key={-1} value={-1}>
              Select a time
            </option>
            {timeOptions.map((time, index) => {
              const isAM = time.hour < 12;
              const hour = padTimePart(
                time.hour < 13 ? time.hour : time.hour - 12
              );
              const min = padTimePart(time.minute);
              const postFix = isAM ? `AM` : `PM`;
              const combined = `${hour}:${min} ${postFix}`;
              return (
                <option key={combined} value={index}>
                  {combined}
                </option>
              );
            })}
          </DropDownCombo>

          <DropDownCombo
            label="Pickup Location"
            name="locationSelector"
            id="locationSelector"
            disabled={addresses.length === 1}
            value={selectedLocationIndex}
            hasError={!!errorMsg}
            required
            onChange={(e) => {
              setSelectedLocation(+e.currentTarget.value);
              setErrorMsg("");
            }}
          >
            <option key={-1} value="-1">
              Select a location
            </option>
            {addresses.map((addr, index) => {
              const bizLocation = addr.json;
              const displayName = bizLocation?.name;
              return <option key={addr.name} value={index}>
                {displayName}
              </option>
            })}
          </DropDownCombo>
        </section>

      </section>

      {errorMsg && (
        <div className={classes.errorMsg}>
          {errorMsg}
        </div>
      )}

      <section className={classes.footer}>
        <StyledButton
          className={classes.button}
          onClick={fetchProductsByDateTime}
          style={{ color: theme?.color }}
        >
          View Menu
        </StyledButton>

        <div dangerouslySetInnerHTML={{ __html: businessInfo?.preOrderLimitWarning ?? "" }} />
      </section>

    </div>
  );
};

export default CateringSelection;
