import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useParams } from "react-router-dom";
import { getFont } from "../../common/themeUtils";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { getMobileOperatingSystem, getOrderMode } from "../../common/app";
import { OrderMode } from "../../constants";
import { clearAllCookies } from "../../common/cookies";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import OrderList from "../../components/OrderList/OrderList";
import AnnouncementPopup from "../../components/Announcement/AnnouncementPopup";
import AdFooter from "../../components/AdFooter/AdFooter";
import { useActions } from "../../hooks/useActions";
import KioskWelcomePage from "../../components/KioskWelcomePage/KioskWelcomePage";
import GiftCardPurchaseWarning from "../../components/WarningSnackbars/GiftCardPurchaseWarning";
import SimpleSnackbar from "../../components/SimpleSnackBar/SimpleSnackBar";
import useExitPrompt from "../../hooks/useExitPrompt";
import RefreshConfirmAlert from "../../components/BrowserBackModal/RefreshConfirmAlert";

const device = getMobileOperatingSystem();

const HomePage = () => {
  const { businessName, viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isKioskViewMode = appViewMode === OrderMode.kiosk;
  const { setOrderMode } = useActions();
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [showCheckout, setShowCheckout] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { itemAddedToCart } = useTypedSelector((state) => state.appLocalStatus);
  const orders = useTypedSelector((state) => state.order);

  useEffect(() => {
    setOrderMode(appViewMode);
  }, [viewMode]);

  useEffect(() => {
    if (isKioskViewMode && businessInfo?.name)
      clearAllCookies(businessInfo.name);
  }, [businessInfo?.name]);

  const setShowSnackBar = (value: boolean) => {
    setSnackbarOpen(value);
  };

  return (
    <div style={{ fontFamily: getFont(businessInfo) }}>
      <GiftCardPurchaseWarning />
      <RefreshConfirmAlert />

      {isKioskViewMode ? <KioskWelcomePage /> : <AnnouncementPopup />}
      <Header />
      <Menu setShowSnackBar={setShowSnackBar} />
      <SimpleSnackbar
        open={snackbarOpen}
        // open={true}
        setOpen={setSnackbarOpen}
        message={`Added ${
          itemAddedToCart?.optionName ?? itemAddedToCart?.name
        }`}
        timeOutSnackBar={3000}
        style={{
          backgroundColor: businessInfo?.theme.color,
          font: businessInfo?.theme?.font,
          color: "white",
          position: "absolute",
          top: "5px",
          left: "0",
          right: "0",
          margin: "auto",
          minWidth: "fit-content",
          maxWidth: "max-content",
        }}
      />

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 2,
        }}
      >
        {orders.finalOrder.finalOrderItems.length > 0 ? (
          <>
            <OrderList />
          </>
        ) : (
          <></>
        )}
        {businessInfo?.adOn && <AdFooter />}
      </div>
    </div>
  );
};

export default HomePage;
