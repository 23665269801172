import { daysSelectOptionsCount } from "../constants";
import { DateOption, DateTimeOption, OrderingType, Time, WorkingHour } from "../Types/Types";
const timeIncrementVal = 30;

enum Days {
  Sun = 0,
  Mon = 1,
  Tue,
  Wed,
  Thu,
  Fri,
  Sat,
}

export const padTimePart = (num: number) => num.toString().padStart(2, '0');

const getHourMinute = (date: Date) => {
  const hour = date.getHours();
  const minute = date.getMinutes();
  return Number(`${hour}${minute}`);
}

const addHourToHourMinute = (hourMinute: number, hourToAdd) => {
  const asStr = hourMinute.toString();
  const hourComponent = +asStr.slice(0, 2);
  const minComponent = +asStr.slice(-2);
  return Number(`${hourComponent + hourToAdd}${minComponent}`);
}

const isDayAvailable = (wh: WorkingHour, day: number, hourMinute: number) => {
  const isSameDay = Days[wh.days] === day;
  const isNowOpen = wh.startTime <= hourMinute && wh.endTime > hourMinute;
  // const isNotOpenYet = hourMinute < wh.startTime;
  const isAvailableToOrder = addHourToHourMinute(hourMinute, 2) <= wh.endTime;
  return isSameDay && (isNowOpen || isAvailableToOrder);
}

const getNowDateTime = () => {
  const nowDateTime = new Date();
  return nowDateTime;
}

const trialLimit = 7;

export const getPreOrderDates = (workingHours?: WorkingHour[]) => {

  const dates: DateOption[] = [];
  if (!workingHours) return dates;

  const sortedWorkingHours = workingHours
    .filter(wh => wh.open && (Days[wh.days] || Days[wh.days] === 0))
    .sort((wh1, wh2) => Days[wh1.days] - Days[wh2.days]);

  const nowDateTime = getNowDateTime();

  let hourMinute = getHourMinute(nowDateTime);
  let nextAvailableWh = sortedWorkingHours.find(
    wh => isDayAvailable(wh, nowDateTime.getDay(), hourMinute + 1)
  );

  let trialCount = 0;
  while (!nextAvailableWh && trialCount <= trialLimit) {
    nowDateTime.setDate(nowDateTime.getDate() + 1);
    const day = nowDateTime.getDay();
    nextAvailableWh = sortedWorkingHours.find(wh => Days[wh.days] === day);
    trialCount++;
  }

  trialCount = 0;
  for (let index = 0; index < daysSelectOptionsCount;) {
    const isDayOpen = sortedWorkingHours.find(wh => Days[wh.days] === nowDateTime.getDay());
    if (isDayOpen) {
      dates.push({
        date: new Date(nowDateTime.getTime()),
        workingHour: isDayOpen
      });
      index++;
    }

    if (trialCount == trialLimit) break;
    nowDateTime.setDate(nowDateTime.getDate() + 1);
  }

  return dates;
}


const deliveryMinimumMinute = 60;
export const currentTimeOptions = (dateOptions: DateOption[], isPreOrderOnly: boolean, orderType?: OrderingType, prepTimeMinimum: number = 0) => {

  const getTimeRange = (dateOption: DateOption) => {
    const nextOpenWh = dateOption.workingHour;
    const nextOpenDate = dateOption.date;

    const closeHour = Math.floor(nextOpenWh.endTime / 100);
    const closeMinute = nextOpenWh.endTime % 100;

    const now = getNowDateTime();

    let earliestWorkingHour = Math.floor(nextOpenWh.startTime / 100);
    let earliestWorkingMinute = nextOpenWh.startTime % 100;

    const timeRanges = { earliestWorkingHour, earliestWorkingMinute, closeHour, closeMinute };
    if (now.getDate() !== nextOpenDate.getDate()) {
      // NOT SAME DAY USE CASE
      return timeRanges;
    }

    // SAME DAY CASES
    if (isPreOrderOnly) {
      // Store Closed
      timeRanges.earliestWorkingHour += 2;
      return timeRanges;
    }

    // prepTimeMinimum === 30 - 5:20 -> 6:00
    // prepTimeMinimum === 30 - 5:40 -> 6:30

    // prepTimeMinimum === 60 - 5:20 -> 6:30
    // prepTimeMinimum === 60 - 5:40 -> 7:00
    
    now.setMinutes(now.getMinutes() + prepTimeMinimum);
    const nowMinutes = now.getMinutes();
    const isOverInterval = nowMinutes > 30;
    const hoursToAdd = isOverInterval ? 1 : 0;
    const newMinutes = isOverInterval ? 0 : 30;

    timeRanges.earliestWorkingHour = now.getHours() + hoursToAdd;
    timeRanges.earliestWorkingMinute = newMinutes;

    return timeRanges;

  }

  const generateTimeOptions = (dateOption: DateOption) => {
    let { earliestWorkingHour, earliestWorkingMinute, closeHour, closeMinute } = getTimeRange(dateOption);
    let half = earliestWorkingMinute === timeIncrementVal ? true : false
    const tempOptionArr: Array<Time> = [];
    while (+`${padTimePart(earliestWorkingHour)}${padTimePart(earliestWorkingMinute)}` <= +`${padTimePart(closeHour)}${padTimePart(closeMinute)}`) {
      if (half) {
        tempOptionArr.push({ hour: earliestWorkingHour, minute: timeIncrementVal })
        earliestWorkingHour++;
        earliestWorkingMinute = 0;
        half = !half;
      } else {
        tempOptionArr.push({ hour: earliestWorkingHour, minute: 0 })
        earliestWorkingMinute = timeIncrementVal;
        half = !half;
      }
    }

    return tempOptionArr;
  }

  const dateTimeOptions: DateTimeOption[] = [];

  dateOptions.forEach(dateOption => {
    const timeOptions = generateTimeOptions(dateOption);
    if (timeOptions.length === 0) return;
    dateTimeOptions.push({
      ...dateOption,
      timeOptions
    })
  })

  return dateTimeOptions;
};
