import classes from "./StoreCloseReason.module.scss";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { OrderMode, StoreOrderingState } from "../../constants";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useParams } from "react-router";
import { getOrderMode } from "../../common/app";
import closedLogo from "../../img/closedLogo.png";

const defaultMsg = "Our online ordering system is closed currently.";

const StoreCloseReason: React.FC = () => {
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const appViewMode = getOrderMode(viewMode);
  const isKioskViewMode = appViewMode === OrderMode.kiosk;
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const [showComponent, setShowComponent] = useState(false);

  const isPreorderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
  useEffect(() => {
    if (isKioskViewMode) return;

    if (businessInfo?.storeTemporarilyClosed) {
      setShowComponent(true);
    }

    const isOpenForOnlineOrdering = businessInfo?.storeOpen;
    if (isOpenForOnlineOrdering && isPreorderOnly) {
      setShowComponent(true);
    }

    if (!isOpenForOnlineOrdering) {
      setShowComponent(true);
    }

  }, [businessInfo?.storeTemporarilyClosed, businessInfo?.storeOpen, isPreorderOnly]);

  // const handleClose = () => {
  //   setShowComponent(false);
  // };

  const Content = (() => {
    if (!businessInfo) return <></>;

    let content = businessInfo.storeClosedReason ?? defaultMsg;
    if (businessInfo.storeTemporarilyClosed) {
      content = businessInfo.storeTemporarilyClosedReason ?? content;
    }

    return (
      <div className={classes.content}
        dangerouslySetInnerHTML={{
          __html: content
        }}>
      </div>
    );
  })();

  // const Footer = (
  //   <div onClick={handleClose} className={classes.footer}>
  //     <hr className={classes.footer_separator} />
  //     <div>
  //       <a >OK</a>
  //     </div>
  //   </div>
  // )

  if (showComponent)
    return (
      <div className={classes.container}>

        <div className={classes.borderDiv} style={viewMode == 'catering' ? {marginTop:'71px'} : {}}>
          <img className={classes.logo} src={closedLogo} alt="" />
          {Content}
        </div>

      </div>
      // <Popup
      //   handleClose={handleClose}
      //   isOpen={popupOpen}
      //   title="Sorry, we're closed now!"
      //   titleStyle={{ fontSize: "16px" }}
      //   popupStyle={{ border: "none" }}
      //   content={Content}
      //   footer={Footer}
      // />
    );

  return <></>;
};

export default StoreCloseReason;
