import Snackbar from "../Snackbar/Snackbar";
import { useEffect, useMemo, useState } from "react";
import { checkGiftCardPurchasable } from "../../common/order";
import { OptionType, discountNotApplicableWarningMessage, giftCardPurchaseWarningMessage } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Typography } from "@material-ui/core";

interface GiftCardPurchaseWarningProps { }

const GiftCardPurchaseWarning = (props: GiftCardPurchaseWarningProps) => {
    const finalOrder = useTypedSelector(state => state.order.finalOrder);
    const businessInfo = useTypedSelector(state => state.businessInfo.businessInfo);
    const [isOpen, setOpen] = useState(false);

    const dscs = businessInfo?.overallDiscounts;

    const isGiftCardNotPurchasable = useMemo(() => {
        return !checkGiftCardPurchasable(finalOrder, businessInfo);
    }, [finalOrder, businessInfo])

    const areOverallDiscountsNotApplicable = useMemo(() => {
        const hasGiftCardsOrEventTickets = finalOrder.finalOrderItems.some((item) => {
            return item.type === OptionType.eventTicket || item.type === OptionType.giftCard;
        })

        const hasOverallDiscounts = dscs?.some(dsc => true) ?? false;
        return hasOverallDiscounts && hasGiftCardsOrEventTickets;
    }, [finalOrder, dscs])

    useEffect(() => {
        setOpen(isGiftCardNotPurchasable || areOverallDiscountsNotApplicable);
    }, [isGiftCardNotPurchasable, areOverallDiscountsNotApplicable])

    const style: React.CSSProperties = {
        fontSize: "1.3rem",
        fontWeight: "bold",
    };

    const message = <Typography align="center">
        {isGiftCardNotPurchasable && <Typography style={style} >{giftCardPurchaseWarningMessage}</Typography>}
        {areOverallDiscountsNotApplicable && <Typography style={style}>{discountNotApplicableWarningMessage}</Typography>}
    </Typography>;

    return (<>
        <Snackbar
            message={message}
            open={isOpen}
            setOpen={() => setOpen(false)}
            autoHide={true}
            severity="warning"
        />
    </>)
}

export default GiftCardPurchaseWarning;
