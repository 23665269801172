import classes from "./LocationSelectorPage.module.scss";
import StyledButton from "../../components/StyledComponent/StyledButton";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { BusinessInfo } from "../../Types/Types";
import { getFont } from "../../common/themeUtils";
import { appendBaseUrlToImage } from "../../common/image";
import { Card, CardActions, CardContent, CardHeader, Typography } from "@material-ui/core";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import PageNotFound from "../notFound/pageNotFound";
import { LocationSelectorUrlParams } from "../ordering/ServiceProviderRoute";
import { formatLocation } from "../../common/address";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";


export interface BizLocation {
    businessId: number,
    serviceProviderUserId: number,
    name: string,
    parentBusinessName: string,
    json?: BusinessInfo,
    stripePublishKey?: null,
    stripeSecretKey?: string,
    pushToken?: string,
    createDate: string,
    updateDate: string,
    visitsCount: number
}


interface LocationSelectorPageProps {
    addresses: BizLocation[]
}


const LocationSelectorPage = (props: LocationSelectorPageProps) => {
    const { addresses } = props;
    const { emptyCart } = useActions();
    const { order, preItems } = useTypedSelector(state => state.order);
    const { serviceProviderUserId, businessName } = useParams<LocationSelectorUrlParams>();
    const history = useHistory();
    const [isPopupOpen, showPopup] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<BusinessInfo>();
    const [goToUrl, setGoToUrl] = useState("");
    const [isPageLoadable, setIsPageLoadable] = useState(true);

    const hasPreItem = preItems && preItems.productOptionId !== 0;
    const isCartNotEmpty = hasPreItem || order.length > 0;
    useEffect(() => {
        if (!isCartNotEmpty) return;
        emptyCart();
    }, [serviceProviderUserId])

    useEffect(() => {
        if (!businessName) {
            setIsPageLoadable(false);
            return;
        };

    }, [serviceProviderUserId])

    useEffect(() => {
        if (addresses.length === 0) return;

        if (addresses.length === 1) {
            const singleAddr = addresses[0];
            history.push(`/${singleAddr.name}`);
        }

        return () => {
            // setAddresses([]);
        }
    }, [addresses])


    if (isPageLoadable === false) return <PageNotFound />;

    const singleAddr = addresses[0];
    const logoSize = 128;

    const placeholderLogo = "https://blog.hubspot.com/hubfs/image8-2.jpg";
    const appendedLogo = appendBaseUrlToImage(singleAddr?.json?.logo);

    const LocationCards = addresses.map(address => {
        const bizLocation = address.json;

        return (
            <Card key={bizLocation?.businessId}
                // elevation={14}
                className={classes.card}>

                <CardHeader className={classes.header} title={bizLocation?.name} />

                {/* <CardMedia
                    className={classes.media}
                    image="/static/images/cards/paella.jpg"
                    title="Paella dish"
                /> */}

                <CardContent className={classes.content}>

                    <Typography variant="h5" color="primary" component="p" className={classes.location}>
                        {formatLocation(bizLocation?.location)}
                    </Typography>

                </CardContent>

                <CardActions disableSpacing
                    className={classes.actions}>

                    <StyledButton isMaterial
                        style={{ background: bizLocation?.theme?.color || "black", fontFamily: getFont(bizLocation) }}
                        onClick={() => {
                            setSelectedLocation(bizLocation);
                            setGoToUrl(bizLocation?.routingPath ?? "");
                            showPopup(true);
                        }}
                        className={classes.button}>
                        Order
                    </StyledButton>

                </CardActions>

            </Card>
        );
    });


    return (
        <div className={classes.page}>

            <ConfirmModal
                title="Confirm Location"
                bodyText2={
                    <>
                        <strong>{selectedLocation?.name}</strong>
                        <span>{` @ `}</span>
                        <strong>{formatLocation(selectedLocation?.location)}</strong>
                    </>
                }
                isOpen={isPopupOpen}
                handleClose={() => showPopup(false)}
                onOk={() => {
                    if (!goToUrl) return;
                    history.push(`/${goToUrl}`);
                }}
            />

            <div className={classes.bizInfo}>
                <img width={logoSize}
                    src={appendedLogo || placeholderLogo}
                    alt={`logo of ${singleAddr?.parentBusinessName || "business name"}`}
                />
                <h3>{singleAddr?.parentBusinessName || "Business name"}</h3>
            </div>

            <div className={classes.buttons}>
                <h2>Choose carefully to order</h2>

                {LocationCards}
            </div>
        </div>
    )
}

export default LocationSelectorPage;
