import { useEffect, useMemo, useState } from 'react';
import { ChoicesById, SectionChoicesById, ChoiceValues, CustomDetailType, CustomizationSection, customizationType, DISCOUNT_VALUE_TYPE, MenuItem } from '../../../Types/Types';
import { calculatePromotionsAmount } from '../../../common/price/price';
import { v4 as uuidv4 } from 'uuid';

export const customFields = ["parentChoiceId", "section", "hasError"];

const choiceListToKeyObject = (section: CustomizationSection, afterFunc?: (choice: CustomDetailType, res: ChoiceValues) => void): ChoicesById => {
    const res = section.choices.reduce((acc, choice) => {
        choice.id = uuidv4()
        acc[choice.id!] = {
            choice,
            value: choice.value ?? 0,
        }
        afterFunc && afterFunc(choice, acc[choice.id!])
        return acc
    }, {} as ChoicesById)

    res.section = section;
    return res;
}

export const useCustomization = (item: MenuItem, index: number) => {

    const [errorSections, setErrorSections] = useState<Array<ChoicesById>>([]);
    const [orderFor, setOrderFor] = useState<string>(item?.selectedCustomization.orderFor ?? "");
    const [itemPrice, setItemPrice] = useState(0);
    const [isRemove, setIsRemove] = useState(false);
    const [count, setLocalCount] = useState(item.count);
    const [specialRequestVal, setSpecialRequestVal] = useState<string>(item?.selectedCustomization.optionSpecialRequest ?? "");

    const oneOfList = item.selectedCustomization.oneOfList ?? [];

    const [integratedArray, setIntegratedArray] = useState(() => {

        const choicesById: ChoicesById = {};
        const choicesBySection: SectionChoicesById = {}

        for (const oneOf of oneOfList.filter(oneOf => oneOf)) {
            const oneOfChoices = choiceListToKeyObject(oneOf, (choice, res) => {
                choicesById[choice.id!] = res;
            })

            oneOf.id = uuidv4()
            choicesBySection[oneOf.id!] = oneOfChoices;

            oneOf.choices.forEach(firstLvlChoice => {
                firstLvlChoice.nestedChoices?.forEach(secondLevelSection => {
                    secondLevelSection.id = uuidv4()
                    const sectionChoicesById = choiceListToKeyObject(secondLevelSection, (choice, res) => {
                        choicesById[choice.id!] = res;
                    })
                    sectionChoicesById.parentChoiceId = firstLvlChoice.id
                    choicesBySection[secondLevelSection.id!] = sectionChoicesById;
                })
            })
        }

        const integratedArray: customizationType = {
            choicesBySection: choicesBySection,
            choicesById: choicesById,
            specialRequest: "",
            itemIndex: index,
            orderFor: orderFor,
        };
        return integratedArray;
    });


    useEffect(() => {
        calculateItemTotal();
    }, [item, count]);

    const normalizeCustomizations = () => {

        const recusiveDeleteIds = (section?: CustomizationSection) => {
            if (!section?.choices) return

            delete section.id;
            section.choices?.forEach(choice => {
                delete choice?.id;
                choice.nestedChoices?.forEach(s => recusiveDeleteIds(s))
            })
        }

        oneOfList.forEach(oneOfSection => {
            recusiveDeleteIds(oneOfSection)
        })

        return item;
    }

    const countPlus = () => {
        setLocalCount(count + 1);

        if (count + 1 > 0) setIsRemove(false);
    };

    const countMinus = () => {
        if (count > 0) setLocalCount(count - 1);

        if (count - 1 === 0) {
            setIsRemove(true);
            setItemPrice(0);
        } else {
        }
    };

    const calculateSelectedCount = (choicesById: ChoicesById) => {
        let count = 0;
        for (const choiceId in choicesById) {
            const choice = choicesById[choiceId];
            count += choice.value ?? 0;
        }

        return count;
    }

    const calculateItemTotal = () => {
        let itemCalculatedPrice = item.price - calculatePromotionsAmount(item.discountRule, item.price);

        const calculateChoicePrice = (choice: CustomDetailType) => {
            const match = integratedArray.choicesById[choice.id!];
            choice.value = match ? match?.value : choice.value;
            return choice.price * choice.value;
        }

        oneOfList?.forEach((oneOfItem, i) => {
            oneOfItem.choices.forEach((choice, idx) => {
                itemCalculatedPrice += calculateChoicePrice(choice);

                choice.nestedChoices?.forEach(nestedSection => {
                    nestedSection.choices?.forEach(nestedChoice => itemCalculatedPrice += calculateChoicePrice(nestedChoice))
                })
            });
        });

        console.log("Intergrated Array: ", integratedArray);
        item.specialRequest = integratedArray.specialRequest
            ? integratedArray.specialRequest
            : item.specialRequest;

        let itemTotal = 0;
        if (DISCOUNT_VALUE_TYPE.bogo === item.discountRule?.type) {
            itemTotal = (Math.floor(count / 2) + count % 2) * itemCalculatedPrice;
        } else {
            itemTotal = itemCalculatedPrice * count;
        }

        setItemPrice(itemTotal)
        return itemTotal;
    };

    const checkOnChange = (choiceId?: string) => {
        if (choiceId === undefined || choiceId === null) return;

        const choice = integratedArray.choicesById[choiceId];
        if (!choice) return;

        choice.value = choice.value ? 0 : 1;

        setIntegratedArray({ ...integratedArray });

        calculateItemTotal();
    };

    const radioOnChange = (sectionId?: string, selectedChoiceId?: string) => {
        if (sectionId === undefined || sectionId === null) return;

        const section = integratedArray.choicesBySection[sectionId];

        for (const choiceId in section) {
            if (customFields.includes(choiceId)) {
                continue;
            }
            const choice = section[choiceId];
            choice.value = selectedChoiceId === choiceId ? 1 : 0
        }

        setIntegratedArray({ ...integratedArray })
        calculateItemTotal();
    };

    const isCustomizationValid = useMemo(() => {

        const entries = Object.entries(integratedArray.choicesBySection);

        const errors: ChoicesById[] = []
        const inValidSection = entries.filter(([key, choicesById]) => {
            if (customFields.includes(key)) return;

            const selectedCount = calculateSelectedCount(choicesById);
            const section = choicesById.section;
            if(section?.max === -1) return

            const parentChoiceValue = integratedArray.choicesById[choicesById.parentChoiceId ?? ''];
            const parentSelected = parentChoiceValue?.value ?? false;
            if (parentChoiceValue && !parentSelected) return;

            const overMax = section?.max ? selectedCount > section.max : false;
            const lessThanMin = section?.min ? selectedCount < section.min : false;

            let isInValid = overMax || lessThanMin;

            if (isInValid) {
                errors.push(choicesById)
            }
            return isInValid;
        })

        setErrorSections(errors)
        return !inValidSection.length;
    }, [integratedArray]);


    return {
        isRemove, errorSections,
        checkOnChange, radioOnChange,
        normalizeCustomizations,
        calculateItemTotal, calculateSelectedCount,
        specialRequestVal, setSpecialRequestVal,
        itemPrice, setItemPrice,
        count, setLocalCount,
        orderFor, setOrderFor,
        countPlus, countMinus,
        integratedArray, setIntegratedArray,
        isCustomizationValid
    };
};
