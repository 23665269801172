import classes from "./MenuCard.module.scss";
import MenuImagePopover from "./MenuImagePopup";
import { DISCOUNT_VALUE_TYPE, MenuOption, MenuType, preItemType } from "../../Types/Types";
import { useActions } from "../../hooks/useActions";
import { useMemo, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { appendBaseUrlToImage } from "../../common/image";
import { calculateCustomizationsAmt, calculatePromotionsAmount, formatPrice } from "../../common/price/price";
import { AddCondition } from "../../constants";
import SignInPopup from "../SignInPopup/SignInPopup";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from "@mui/material/CardMedia";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Widgets } from "@material-ui/icons";

interface MenuCardProps {
  menu: MenuType;
  getStyle: string;
  UnescapeHTML: Function;
  addCart: Function;
  index: number;
  option: MenuOption;
  isAfterSwipe?: boolean;
  setIsAfterSwipe?: Function;
  setShowSnackBar: Function;
}

const MenuCardItem = ({
  menu,
  getStyle,
  UnescapeHTML,
  addCart,
  index,
  option,
  isAfterSwipe,
  setIsAfterSwipe,
  setShowSnackBar
}: MenuCardProps) => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { isSignedIn } = useTypedSelector(state => state.account);
  const { preItem } = useActions();
  const [showModal, setShowModal] = useState(false);
  const [openReqSignInModal, setOpenReqSignInModal] = useState(false);

  const isOptionSoldout = menu.options[index].soldout;

  const requireSignIn = option.addCondition === AddCondition.signedIn;
  const requireSignInAndNotSignedIn = requireSignIn && isSignedIn === false;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handlePreItem = () => {
    if (requireSignInAndNotSignedIn) {
      setOpenReqSignInModal(true);
      return;
    }

    if (isAfterSwipe) {
      setIsAfterSwipe && setIsAfterSwipe(false);
      return;
    };

    if (isOptionSoldout) return;

    const newMenu = JSON.parse(JSON.stringify(menu));
    const productOption = newMenu.options[index] as MenuOption;

    const orderItem: preItemType = {
      count: 1,
      selectedCustomization: { ...productOption.customization, optionSpecialRequest: "" },
      disabled: productOption.disabled,
      disabledReason: productOption.disabledReason,
      name: newMenu.name,
      image: newMenu.image,
      description: newMenu.description,
      optionsType: newMenu.optionsType,
      discountRule: productOption.discountRule,
      optionName: productOption.label,
      price: productOption.price,
      productOptionId: productOption.id,
      productImage: productOption.optionImage,
      addCondition: productOption.addCondition,
      type: productOption.type,
      productDescription: productOption.optionDescription,
      specialRequest: "",
    };

    preItem(orderItem);
    setShowModal(true);

  };

  const handleOnClose = () => {
    setShowModal(false);
  };

  const image = useMemo(() => {
    const menuImage = appendBaseUrlToImage(menu.image);
    const optionImage = appendBaseUrlToImage(option.optionImage);
    const newImage = optionImage || menuImage;
    return newImage;
  }, [menu.image, option.optionImage]);

  const customizationAmt = calculateCustomizationsAmt(option);
  const discountAmt = calculatePromotionsAmount(option.discountRule, option.price);
  const discountedPrice = option.price + customizationAmt - discountAmt;
  const formattedPrice = formatPrice(option.price + customizationAmt);
  const logo = appendBaseUrlToImage(businessInfo?.logo);

  const isBogoEnabled = option.discountRule?.type === DISCOUNT_VALUE_TYPE.bogo;
  const hasDiscount = isBogoEnabled || option.discountRule?.value > 0;
  let offerText;

  if (hasDiscount) {
    switch (option.discountRule.type) {
      case DISCOUNT_VALUE_TYPE.percentage:
        offerText = ` \u00A0${option.discountRule.value}% off`;
        break;
      case DISCOUNT_VALUE_TYPE.amount:
        offerText = ` \u00A0$${discountAmt / 100} off`;
        break;
      case DISCOUNT_VALUE_TYPE.bogo:
        offerText = option.discountRule.title;
        break;
    }
  }


  const handleAdd = (e) => {
    if (requireSignInAndNotSignedIn) {
      setOpenReqSignInModal(true);
      return;
    }

    if (isAfterSwipe) {
      setIsAfterSwipe && setIsAfterSwipe(false);
      return;
    };

    if (option.forceCustomizationBeforeAdd) {
      handlePreItem();
      return;
    }

    setShowSnackBar(true);
    addCart(index);
  };
  const getAddIconStyle = () => {
    return {
      ...businessInfo?.theme,
      fontSize: "40px"
    }
  }
  return (
    <>
      <li key={index} className={classes.menu} style={{ color: getStyle }}>
        {requireSignInAndNotSignedIn && (
          <SignInPopup
            isOpen={openReqSignInModal}
            handleClose={() => setOpenReqSignInModal(false)}
          />
        )}

        <Card className={classes.outer_card}>
          <CardContent className={classes.card__content}>
            <div className={classes.menu__picture}>
              <div
                onClick={handlePreItem}
                className={classes.menu__picture_inner}
              >
                {hasDiscount ? (
                  <span className={classes.card_tag}
                    style={{ width: isBogoEnabled ? "84%" : "78px" }}
                  >
                    <LocalOfferIcon
                      style={{
                        width: "11.67px",
                        top: "1.17px",
                        left: "1.17px",
                        height: "11.67px",
                      }}
                    ></LocalOfferIcon>
                    {offerText}
                  </span>
                ) : (
                  <></>
                )}
                {image ? (
                  <div className={classes.menu__picture_front}>
                    {isOptionSoldout ? (
                      <div className={classes.menu__picture_front_soldout}>
                        <CardMedia
                          component="img"
                          alt={option.label}
                          height="140px"
                          image={image}
                        />
                        <span>Sold Out</span>
                      </div>
                    ) : (
                      <CardMedia
                        component="img"
                        alt={option.label}
                        height="140px"
                        image={image}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {isOptionSoldout ? (
                      <div className={classes.menu__picture_front_soldout}>
                        <span className={classes.no_image_soldout}>
                          Sold Out
                        </span>
                      </div>
                    ) : (
                      <CardMedia
                        style={{
                          height: "80px",
                          width: "80px",
                          opacity: "0.25",
                        }}
                        component="img"
                        alt={option.label}
                        height="140px"
                        image={logo}
                      />
                    )}
                  </>
                )}
              </div>
            </div>

            <Typography variant="h6" className={classes.menu__option}>

              <div className={classes.menu__containerlabel}>
                <div className={classes.menu__labelContainer}>
                  <span className={classes.menu__label}>{option.label}</span>
                </div>
              </div>
              <div className={classes.menu_outer_lay}>
                <div className={classes.menu__container}>
                  {hasDiscount && option.discountRule.type !== DISCOUNT_VALUE_TYPE.bogo ? (
                    <div className={classes.menu__container_price}>
                      <div className={classes.menu__container_price_discount}>
                        <span
                          className={classes.menu__container_price_original}
                        >
                          {formattedPrice}
                        </span>
                        <span
                          className={classes.menu__container_price_discounted}
                        >
                          {formatPrice(discountedPrice)}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <span className={classes.menu__container_price_nodiscount}>
                      {formattedPrice}
                    </span>
                  )}
                </div>

                <div className={classes.menu__container_add}>
                  {isOptionSoldout ? (
                    <AddCircleIcon
                      fontSize="small"
                      className={classes.menu__container_icon}
                      style={{
                        visibility: "hidden",
                        color: "red",
                        fontSize: "30px",
                      }}
                    />
                  ) : (
                    !businessInfo?.menuOnly &&
                    !option.disabled && (
                      <div
                        onClick={handleAdd}
                        className={classes.menu__container_add}
                      >
                        <AddCircleIcon
                          fontSize="large"
                          className={classes.menu__container_add_icon}
                          style={getAddIconStyle()}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </Typography>
            {showModal && (
              <MenuImagePopover index={index} setShowModal={handleOnClose} />
            )}
          </CardContent>
        </Card>
      </li>
    </>
  );
};

export default MenuCardItem;
