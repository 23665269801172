import React, { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import classes from "./scss/CustomizeModal.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  MenuItem,
  preItemType,
} from "../../Types/Types";
import { useActions } from "../../hooks/useActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appendBaseUrlToImage } from "../../common/image";
import { formatPrice } from "../../common/price/price";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  AddCondition,
  defaultRequestPlaceholder,
  OptionType,
} from "../../constants";
// MUI imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCustomization } from "./hooks/useCustomization";
import { AnyOfSection } from "./CustomizationSection";


interface CustomizeModalProps {
  index: number;
  isOpen: boolean;
  style: Styles;
  setShowCustomize: (state: boolean) => void;
  type: "pre" | "cart";
  setCount?: Function;
  closeModal?: Function;
}

const CustomizeModal: React.FC<CustomizeModalProps> = ({
  index,
  isOpen,
  style,
  setShowCustomize,
  type,
  setCount,
  closeModal,
}) => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { credential } = useTypedSelector((state) => state.account);
  const { addCustomization, setInfoToFinalOrder, addItem, removeItem } = useActions();
  const [scroll, setScroll] = useState<number>(1);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [hasOverFlow, setHasOverflow] = useState<boolean>(false);
  const orders = useTypedSelector((state) => state.order);
  const cartOrders = useTypedSelector((state) => state.order.finalOrder.finalOrderItems[index]);
  const preItems = useTypedSelector((state) => state.order.preItems);
  const optionOrder: MenuItem = type === "cart" ? cartOrders : preItems;

  const [innerModalRef, setInnerModalRef] = useState<HTMLElement | null>();

  const customizationContext = useCustomization(optionOrder, index);
  const { isRemove, errorSections } = customizationContext;
  const { count, integratedArray } = customizationContext;
  const { itemPrice, isCustomizationValid } = customizationContext;
  const { specialRequestVal, setSpecialRequestVal } = customizationContext;
  const { normalizeCustomizations } = customizationContext;
  const { orderFor, setOrderFor } = customizationContext;
  const { countPlus, countMinus } = customizationContext;

  const oneOfList = optionOrder?.selectedCustomization?.oneOfList;
  const menuName = optionOrder?.name;

  useEffect(() => {
    console.log("order is ", orders);
    console.log("orders is ", optionOrder);
    console.log("ITEM PRICE: ", itemPrice);
  }, [itemPrice, orders, optionOrder]);

  useEffect(() => {
    if (!innerModalRef) return;
    setHasOverflow(innerModalRef?.scrollHeight > innerModalRef?.clientHeight);
  }, [innerModalRef]);

  const addCustomize = () => {

    if (!isCustomizationValid) {
      console.log(errorSections);
      if (!document?.getElementById) return

      const sectionId = errorSections[0]?.section?.id ?? ''
      const elem = document.getElementById(sectionId) as HTMLInputElement
      elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return;
    }

    integratedArray.specialRequest = specialRequestVal;

    const item = normalizeCustomizations()

    if (type === "cart" && setCount) {
      setCount(index, count);
      addCustomization(integratedArray);
      setInfoToFinalOrder();
      setShowCustomize(false);
    } else {
      const addToCart: preItemType = {
        ...optionOrder,
        count,
        selectedCustomization: {
          ...item.selectedCustomization,
          optionSpecialRequest: specialRequestVal,
          orderFor: orderFor,
        },
      };
      // TODO: Validation can be done here
      addItem(addToCart);
      setInfoToFinalOrder();
      if (closeModal) closeModal();
    }
  };

  const closeCustom = () => {
    if (type === "pre" && closeModal) {
      closeModal();
    } else {
      setShowCustomize(false);
    }
  };

  const getTheme = () => {
    if (!businessInfo) return {};
    return { backgroundColor: businessInfo.theme.color };
  };

  const handleScroll = () => {
    const section = document.getElementsByClassName("modal-inner")[0];
    const diff = Math.floor(section.scrollHeight - section.scrollTop);

    if (diff <= section.clientHeight) {
      section.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setScroll(1);
      setIsBottom(false);
    } else {
      section.scrollTo({
        top: section.scrollTop + 200 * scroll,
        behavior: "smooth",
      });
      setScroll(scroll + 1);
    }
  };

  const elementScrollData = (event: any) => {
    const target = event.target;
    const diff = Math.floor(target.scrollHeight - target.scrollTop);
    const clientHeight = Math.floor(target.clientHeight);

    if (diff <= clientHeight) {
      setIsBottom(true);
      setScroll(1);
    } else {
      setIsBottom(false);
    }
  };

  const getButtonValue = () => {
    if (isRemove) {
      return "Remove";
    } else if (type === "cart") {
      return "Update ";
    } else {
      const isDisabled = businessInfo?.menuOnly || optionOrder.disabled;
      return isDisabled ? "" : "Add ";
    }
  };

  const removeItemFromCart = () => {
    setShowCustomize(false);
    removeItem(cartOrders);
    setInfoToFinalOrder();
  };

  const isNonGiftCardOption = optionOrder.type !== OptionType.giftCard;
  const image = appendBaseUrlToImage(optionOrder.productImage || optionOrder.image);
  const description = optionOrder.productDescription || optionOrder.description;
  const signInDesc = (() => {
    if (optionOrder.addCondition !== AddCondition.signedIn) return "";
    if (optionOrder.type === OptionType.giftCard)
      return "The gift card details will be emailed to " + credential.email;
    if (optionOrder.type === OptionType.eventTicket)
      return "The ticket(s) will be emailed to " + credential.email;
  })();

  return (
    <Modal
      isOpen={isOpen}
      style={style}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeCustom}
      className={classes.customize}
    >
      <section
        className={`${classes.customize__modalInner} modal-inner`}
        onScroll={elementScrollData}
        ref={(el) => {
          setInnerModalRef(el);
        }}
      >
        <div className={classes.customize__menu}>
          <Box
            className={classes.header}
            sx={{
              backgroundColor: "primary.main",
            }}
          >
            <CloseIcon onClick={closeCustom} />
            <Typography fontSize="1.5rem" fontWeight={800}>
              {menuName}
              {optionOrder.optionName.trim() ? (
                <> - {optionOrder.optionName}</>
              ) : (
                <></>
              )}
            </Typography>
          </Box>

          {optionOrder.disabled && (
            <div className={classes.disabledReason}>
              {optionOrder.disabledReason}
            </div>
          )}
          <Card sx={{ display: "flex", boxShadow: "none", width: "100%" }}>
            <Box sx={{ display: "flex", padding: "1.5rem .5rem", gap: "1rem" }}>
              {image ? (
                <CardMedia
                  component="img"
                  sx={{
                    width: "80px",
                    height: "80px",
                    boxShadow: "none",
                    borderRadius: "12px",
                  }}
                  image={image}
                  alt="menu"
                />
              ) : (
                <></>
              )}

              <CardContent
                sx={{
                  display: "flex",
                  flex: "1 0 ",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  alignItems: "column",
                  padding: "0 16px",
                }}
              >
                <Typography component="div" fontSize={"14px"} color="#1D1B20">
                  {description}
                </Typography>
                <Typography component="div" fontSize={"14px"} color="#1D1B20">
                  {signInDesc}
                </Typography>
              </CardContent>
            </Box>
          </Card>

        </div>
        <Box
          sx={{
            padding: "0 1rem",
          }}
        >
          {/* page 2 */}
          {oneOfList?.map((oneOfChoice, i) => {

            return <AnyOfSection
              section={oneOfChoice}
              customizationContext={customizationContext}
              sectionChoicesById={integratedArray.choicesBySection}
            />;
          })}

          {/* page 4 */}
          {isNonGiftCardOption && (
            <>
              <div className={classes.customize__menu_request}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { width: "100%" },
                    padding: "1rem 0 !important",
                  }}
                >
                  <TextField
                    placeholder={
                      businessInfo?.specialRequestHint ||
                      defaultRequestPlaceholder
                    }
                    id="standard-multiline-static"
                    label="Special request"
                    helperText="Is there anything we need to know to prepare your order?"
                    multiline
                    rows={2}
                    variant="standard"
                    inputProps={{ maxLength: 100 }}
                    value={specialRequestVal}
                    onChange={(e) => {
                      setSpecialRequestVal(e.target.value);
                    }}
                  />

                  <TextField
                    placeholder="Alice"
                    id="standard-multiline-static"
                    label="Order For"
                    helperText="E.g. Alice's order"
                    variant="standard"
                    value={orderFor}
                    onChange={(e) => {
                      setOrderFor(e.target.value);
                    }}
                    inputProps={{ maxLength: 50 }}
                    sx={{
                      marginTop: "1rem",
                    }}
                  />
                </Box>
              </div>
            </>
          )}
        </Box>
        <Box
          className={classes.customize__menu_button}
          sx={{ display: "flex", width: "100%", padding: "1rem 0" }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0 1rem ",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {hasOverFlow && (
              <div
                onClick={handleScroll}
                className={classes.customize__menu_scroll_button}
                style={getTheme()}
              >
                {isBottom ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </div>
            )}
            <Stack
              direction="row"
              spacing={1}
              sx={{
                backgroundColor: "primary.main",
                borderRadius: "100px",
              }}
            >
              <IconButton
                aria-label="delete"
                onClick={countMinus}
                disabled={count === 0}
              >
                {count > 1 ? (
                  <RemoveIcon
                    sx={{
                      color: "#fff",
                    }}
                  />
                ) : (
                  <DeleteIcon
                    sx={{
                      color: "#fff",
                    }}
                  />
                )}
              </IconButton>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                {count}
              </Typography>
              <IconButton aria-label="add" onClick={countPlus}>
                <AddIcon
                  sx={{
                    color: "#fff",
                  }}
                />
              </IconButton>
            </Stack>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "#fff",
                padding: "10px 24px 10px 16px",
                borderRadius: "100px",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                minWidth: "180px",
              }}
              className={classes.actionButton}
              onClick={isRemove ? removeItemFromCart : addCustomize}
              disabled={businessInfo?.menuOnly || optionOrder.disabled}
            >
              <Typography fontSize="20px" fontWeight={500} color={"#fff"}>
                {getButtonValue()} {!isRemove && `${formatPrice(itemPrice)}`}
              </Typography>
            </Button>
          </Box>
        </Box>
      </section>
    </Modal>
  );
};

export default CustomizeModal;
