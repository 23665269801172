import { orderItemType, FinalOrderPayload, preItemType } from "./../../Types/Types";
import { calculateItemFullPrice, calculateItemTotal, calculateTip } from "../../common/price/price";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { calculateNoTaxSubTotal, calculatePaperBagFee, calculateTax } from "../../common/order";
import { initialPreItemState } from "./utils/initialStates";
import { initialFinalOrderState } from "./utils/initialStates";
import { initialState } from "./utils/initialStates";
import { OrderState } from "./utils/reducerTypes";

const findAndUpdateSameItems = (newItem: orderItemType, currentOrders: orderItemType[]) => {
  const customizationJsonString = JSON.stringify(newItem.selectedCustomization);
  const sameCartItem = currentOrders.find((orderItem) => {
    const jsonString = JSON.stringify(orderItem.selectedCustomization);
    const hasSameCustomization = jsonString === customizationJsonString;
    const hasSameName = orderItem.name === newItem.name;
    const hasSameOptionName = orderItem.optionName === newItem.optionName;
    return hasSameName && hasSameOptionName && hasSameCustomization;
  });
  if (sameCartItem) {
    sameCartItem.count++;
    return true;
  }

  return false;
}


const reducer = (
  state: OrderState = initialState,
  action: Action
): OrderState => {

  let newState = state;
  let newOrder: orderItemType[] = state.order;
  let newPreItems: preItemType = state.preItems;
  let newFinalOrderItems: orderItemType[] = state.finalOrder.finalOrderItems;
  let newFinalOrder: FinalOrderPayload = state.finalOrder;

  switch (action.type) {
    case ActionType.ADD_ITEM:
      const payload = action.payload;
      console.log("CURRENT FINAL: ", newFinalOrder);
      console.log("PAYLOAD: ", payload);

      if (payload.count <= 0) return state;

      if (findAndUpdateSameItems(payload, state.order)) {
        return state;
      }

      newOrder.push(payload);
      newFinalOrderItems.push(payload);

      return { ...state, order: newOrder, finalOrder: { ...newFinalOrder } };

    case ActionType.PRE_ITEM:
      console.log("payload: ", action.payload);
      newPreItems = { ...action.payload };
      return { ...state, preItems: { ...newPreItems } };

    case ActionType.SET_COUNT_ITEM:
      const orderItem = newOrder[action.payload.index];
      orderItem.count = action.payload.count;
      if (orderItem.count < 1) {
        state.order.splice(action.payload.index, 1);
      }
      return { ...state, order: newOrder };

    case ActionType.INCREMENT_ITEM:
      newOrder[action.payload.index].count++;
      return { ...state, order: newOrder };

    case ActionType.DECREMENT_ITEM:
      newOrder[action.payload.index].count--;
      if (newOrder[action.payload.index].count < 1) {
        state.order.splice(action.payload.index, 1);
      }
      return { ...state, order: newOrder };

    case ActionType.REMOVE_ITEM:
      console.log("NEW ORDER: ", newOrder);
      console.log("NEW FINAL ORDER: ", newFinalOrder);

      const itemToRemove = action.payload;
      const newJson = JSON.stringify(itemToRemove.selectedCustomization);
      const ind = newOrder.findIndex((item, i) => {
        if (item.productOptionId !== itemToRemove.productOptionId) {
          return false;
        }

        const cartJson = JSON.stringify(item.selectedCustomization);
        return newJson === cartJson;
      });

      if (ind !== -1) {
        newOrder.splice(ind, 1);
      }

      newFinalOrder.finalOrderItems = [...newOrder];
      return { ...state, order: newOrder, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_INFO_TO_FINAL_ORDER:

      const { businessInfo, isTableViewMode } = action.payload;
      if (isTableViewMode) {
        newFinalOrder.paperBagCount = 0;
      }

      const accummulatedSubTotal = newOrder.reduce((acc, cur) => acc + calculateItemTotal(cur), 0)
      let subTotalWithTax = Math.floor(accummulatedSubTotal);

      let deliveryFee = 0;
      if (newFinalOrder.orderType === "delivery") {
        deliveryFee = (businessInfo?.minimumDeliveryFee ?? 0) * 100;
        const deliveryAddress = newFinalOrder.deliveryAddress;
        if (businessInfo?.perExtraKilometerFee && deliveryAddress.distance) {

          const deliveryMinFeeRange = businessInfo?.firstDeliveryKilometersRange ?? 0;
          const distanceLeftInMeters = deliveryAddress.distance - (deliveryMinFeeRange * 1000);
          const distanceLeftInKm = distanceLeftInMeters / 1000;
          if (distanceLeftInKm >= 0) {
            deliveryFee += businessInfo.perExtraKilometerFee * distanceLeftInKm * 100;
          }
        }
        const freeDeliveryMinOrder = (businessInfo?.minOrderAmountForFreeDelivery ?? 0) * 100;
        if (freeDeliveryMinOrder && subTotalWithTax >= freeDeliveryMinOrder) {
          deliveryFee = 0;
        }
      }


      newFinalOrder.deliveryFee = deliveryFee;

      const noTaxSubTotal = Math.floor(calculateNoTaxSubTotal(newFinalOrder));
      const tax = Math.floor(calculateTax(subTotalWithTax + deliveryFee, noTaxSubTotal));
      newFinalOrder.tax = tax;
      newFinalOrder.subTotal = subTotalWithTax;

      const calcPaperBagFee = calculatePaperBagFee(newFinalOrder);
      const flooredCalculatedTip = calculateTip(newFinalOrder);

      const sum = subTotalWithTax + tax + flooredCalculatedTip + calcPaperBagFee + deliveryFee;
      newFinalOrder.total = Math.floor(sum);

      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_COMPLETE_FINAL_ORDER:
      console.log("BEFORE UPDATING FINAL ORDER:\n", newFinalOrder);
      newFinalOrder = { ...action.payload };
      console.log("ORDER ORDER: ", newOrder);
      console.log("NEW FINAL: ", newFinalOrder);
      return { ...state, finalOrder: { ...action.payload } };

    case ActionType.SET_DISCOUNTS_TO_FINAL_ORDER:
      newFinalOrder.calculatedDiscounts = action.payload;
      return { ...state, finalOrder: newFinalOrder }

    case ActionType.SET_MOBILE_DISCOUNTS_TO_FINAL_ORDER:
      newFinalOrder.calculatedMobilePayDiscount = action.payload;
      return { ...state, finalOrder: newFinalOrder }

    case ActionType.UPDATE_CUSTOMIZE:
      let index = action.payload.itemIndex ? action.payload.itemIndex : 0;
      let item = newOrder[index];

      item.specialRequest =
        index === action.payload.itemIndex
          ? action.payload.specialRequest
          : item.specialRequest;

      item.selectedCustomization.optionSpecialRequest =
        index === action.payload.itemIndex
          ? action.payload.specialRequest
          : item.specialRequest;

      item.selectedCustomization.orderFor =
        index === action.payload.itemIndex
          ? action.payload.orderFor
          : item.selectedCustomization.orderFor;

      return { ...state, order: newOrder };

    case ActionType.CHANGE_ORDER:
      // newFinalOrder.orderNumber = action.payload
      console.log("PAYLOAD: ", action.payload);

      newFinalOrder = { ...action.payload };
      newOrder = action.payload.finalOrderItems;
      console.log("NEW FINAL: ", newFinalOrder);
      console.log("NEW ORDER: ", newOrder);

      // add tip, email etc

      return {
        ...state,
        order: [...newOrder],
        finalOrder: { ...newFinalOrder },
      };

    case ActionType.EMPTY_CART:
      console.log("initialFinalOrderState: ", initialFinalOrderState);

      newState = {
        finalOrder: { ...initialFinalOrderState, finalOrderItems: [] },
        order: [],
        preItems: { ...initialPreItemState },
      };
      console.log("New State: ", newState);

      // return newState;
      return { ...newState };

    case ActionType.SET_SPECIAL_NOTES:
      newFinalOrder.instructions = action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_ORDER_TYPE:
      const newOrderType = action.payload;

      newFinalOrder.orderType = newOrderType;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_USER_NAME:
      newFinalOrder.customerName = action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_USER_NUMBER:
      newFinalOrder.userPhoneNumber = action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_TIP_PERCENTAGE:
      newFinalOrder.selectedTipPercentage = +action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } }

    case ActionType.SET_PREORDER_DATETIME:
      const dateMilliseconds = action.payload;
      newFinalOrder.requestedPrepDateTime = dateMilliseconds;
      newFinalOrder.requestedPrepDateTimeString = dateMilliseconds ? new Date(dateMilliseconds).toJSON() : dateMilliseconds?.toString();

      return { ...state, finalOrder: { ...newFinalOrder } }

    case ActionType.SET_ORDER_USER_ID:
      newFinalOrder.userId = action.payload.toString();
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.CLEAR_ORDER_USER_ID:
      newFinalOrder.userId = undefined;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_PAPER_BAG_COUNT:
      newFinalOrder.paperBagCount = action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_PAPER_BAG_FEE:
      newFinalOrder.paperBagFee = action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_ORDER_MODE:
      newFinalOrder.orderMode = action.payload;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_SMS_NOTIFICATION_REQUESTED:
      const isRequested = action.payload;
      newFinalOrder.smsNotificationRequested = isRequested;
      return { ...state, finalOrder: { ...newFinalOrder } };

    case ActionType.SET_DELIVERY_INFO:
      const deliveryPayload = action.payload;

      return { ...state, finalOrder: { ...newFinalOrder, deliveryAddress: { ...deliveryPayload } } };

    default:
      return state;
  }
};

export default reducer;

