import { useCallback, useMemo, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { calculateDiscountAmt, calculateCustomizationsAmt, calculatePromotionsAmount } from "../../../common/price/price";
import { OptionType, diningOptionValues, orderTypeMap } from "../../../constants";
import { DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE, OrderingType, BusinessDiscount } from "../../../Types/Types";
import { checkGiftCardPurchasable } from "../../../common/order";


const initialValue: BusinessDiscount = {
    amount: 0,
    title: "",
    type: DISCOUNT_VALUE_TYPE.amount,
    value: 0,
};

export const acceptedDiscountTypes = [
    "All",
    "promoCode"
]

const usePriceAdjustments = () => {
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const finalOrder = useTypedSelector((state) => state.order.finalOrder);
    const destinationInfo = finalOrder.deliveryAddress;

    const isGiftCardPurchasable = useMemo(() =>
        checkGiftCardPurchasable(finalOrder, businessInfo),
        [finalOrder, businessInfo]);

    const [showCreditCardForm, setShowCreditCardForm] = useState(false);

    const overallDiscounts = businessInfo?.overallDiscounts;

    const notApplicableItemsTotal = useMemo(() => {
        return finalOrder.finalOrderItems
            .filter(orderItem => orderItem.type === OptionType.eventTicket || orderItem.type === OptionType.giftCard)
            .reduce((acc, option) => {
                const discountAmt = calculatePromotionsAmount(option.discountRule, option.price);
                const customizationAmt = calculateCustomizationsAmt(option);
                const discountedPrice = option.price + customizationAmt - discountAmt;

                return acc + (discountedPrice * option.count)
            }, 0);
    }, [finalOrder])

    const validItemsSubTotal = finalOrder.subTotal - notApplicableItemsTotal;
    const discountsWithAmt = useMemo(() => overallDiscounts?.map((dsc) => {
        return {
            ...dsc,
            amount: calculateDiscountAmt(dsc, validItemsSubTotal),
        }
    }) ?? [], [overallDiscounts, validItemsSubTotal]);

    const hasDiningOptionDiscounts = useMemo(() => {
        const dscList = discountsWithAmt.filter((discount) => {
            const lessThanMinSub = discount.minPurchase !== undefined && finalOrder.subTotal < discount.minPurchase;
            if (lessThanMinSub) {
                return false;
            }

            return true;
        });

        const orderTypeKeys = Object.values(orderTypeMap);
        const match = dscList.find(x => x.discountType && orderTypeKeys.includes(x.discountType));
        return !!match;
    }, [validItemsSubTotal, discountsWithAmt]);

    const highestDiscount = useMemo(() => {
        if (!discountsWithAmt || discountsWithAmt.length === 0) {
            return null;
        }

        const diningOpt = finalOrder.orderType;
        const dscList = discountsWithAmt
            .filter(x => x.discountType === diningOpt || !x.discountType || acceptedDiscountTypes.includes(x.discountType))
            .filter((discount) => {
                const lessThanMinSub = discount.minPurchase !== undefined && finalOrder.subTotal < discount.minPurchase;
                if (lessThanMinSub) {
                    return false;
                }

                return true;
            });

        const max = dscList.reduce((prev, current) =>
            (prev && prev.amount > current.amount) ? prev : current
            , initialValue);

        return max === initialValue ? null : max;
    }, [discountsWithAmt]);

    const getHighestDiscount = useCallback((diningOpt: OrderingType) => {
        if (!diningOpt) {
            return null;
        }

        if (!discountsWithAmt || discountsWithAmt.length === 0) {
            return null;
        }

        const dscList = discountsWithAmt
            .filter(x => x.discountType === diningOpt)
            .filter((discount) => {
                const lessThanMinSub = discount.minPurchase !== undefined && finalOrder.subTotal < discount.minPurchase;
                if (lessThanMinSub) {
                    return false;
                }
                return true;
            });

        const max = dscList.reduce((prev, current) =>
            (prev && prev.amount > current.amount) ? prev : current
            , initialValue);
        return max === initialValue ? null : max;
    }, [discountsWithAmt]);

    const surcharges = useMemo(() => {
        let totalAppliedSurchargeAmt = 0;
        if (!discountsWithAmt) {
            return {
                priceAdjustments: [],
                totalAppliedSurchargeAmt
            }
        }

        const priceAdjustments = discountsWithAmt
            .filter((dsc) => dsc.discountType !== DISCOUNT_TYPE.credit)
            .filter((dsc) => !(!showCreditCardForm && dsc.discountType === DISCOUNT_TYPE.mobilePay))
            .filter((dsc) => dsc.value < 0)
            .filter((surcharge) => {
                const discountIsDiningOption = diningOptionValues.includes(surcharge.discountType ?? "");
                const isSelectedOrderTypeDiscount = finalOrder.orderType === surcharge.discountType;
                if (discountIsDiningOption && !isSelectedOrderTypeDiscount) {
                    return false;
                }

                const lessThanMinSub = surcharge.minPurchase !== undefined && finalOrder.subTotal < surcharge.minPurchase;
                if (lessThanMinSub) {
                    return false;
                }

                totalAppliedSurchargeAmt += surcharge.amount;
                return true;
            });

        return {
            priceAdjustments,
            totalAppliedSurchargeAmt,
        };
    }, [validItemsSubTotal, finalOrder.subTotal, discountsWithAmt, showCreditCardForm]);

    const notApplicablePriceAdjs = useMemo(() => {
        if (!businessInfo?.overallDiscounts) {
            return [];
        }

        let priceAdjustments = businessInfo.overallDiscounts.filter(x => x.discountType === finalOrder.orderType);

        priceAdjustments = priceAdjustments.filter((priceAdj) => {
            const lessThanMinSub = priceAdj.minPurchase !== undefined && finalOrder.subTotal < priceAdj.minPurchase;
            if (lessThanMinSub) {
                return true;
            }

            return false;
        }
        );

        return priceAdjustments ?? [];
    }, [businessInfo?.overallDiscounts]);

    const perKmFee = useMemo(() => {
        let fee = 0;
        if (!businessInfo?.perExtraKilometerFee) return fee;
        if (!destinationInfo.distance) return fee;

        const deliveryMinFeeRange = businessInfo?.firstDeliveryKilometersRange ?? 0;
        const distanceLeftInMeters = destinationInfo.distance - (deliveryMinFeeRange * 1000);
        const distanceLeftInKm = distanceLeftInMeters / 1000;
        if (distanceLeftInKm < 0) return fee;

        fee = businessInfo.perExtraKilometerFee * distanceLeftInKm * 100;
        return fee;
    }, [businessInfo?.firstDeliveryKilometersRange, businessInfo?.perExtraKilometerFee, destinationInfo.distance]);

    const calculateDeliveryFees = useCallback((newTotal: number) => {
        let fee = (businessInfo?.minimumDeliveryFee ?? 0) * 100;

        const freeDeliveryMinOrder = (businessInfo?.minOrderAmountForFreeDelivery ?? 0) * 100;
        if (freeDeliveryMinOrder && newTotal >= freeDeliveryMinOrder) {
            fee = 0;
            return fee;
        }

        fee += perKmFee;
        return fee;
    }, [destinationInfo, perKmFee]);

    const calculateCreditAmt = useCallback((newTotal: number) => {
        let creditAmtToApply = 0;
        const creditDsc = discountsWithAmt?.find((dsc) => dsc.discountType === DISCOUNT_TYPE.credit);
        if (creditDsc && isGiftCardPurchasable && newTotal > 0) {
            creditAmtToApply = newTotal < creditDsc.amount ? newTotal : creditDsc.amount;
            creditDsc.amount = creditAmtToApply;
        }

        return { creditAmtToApply, creditDsc };
    }, [discountsWithAmt]);

    const normalizeDiscounts = useCallback((priceAdjustments) => {
        const calculatedDiscounts = priceAdjustments?.map((dsc) => ({
            value: dsc.value,
            title: dsc.title,
            type: dsc.type,
            amount: dsc.amount,
            discountType: dsc.discountType,
            maxPurchase: dsc.maxPurchase,
        })) || [];

        return calculatedDiscounts;
    }, []);

    return {
        hasDiningOptionDiscounts,
        highestDiscount, getHighestDiscount,
        discountsWithAmt, surcharges, 
        notApplicablePriceAdjs,
        normalizeDiscounts,
        calculateCreditAmt,
        showCreditCardForm, setShowCreditCardForm,
        calculateDeliveryFees
    };
}

export default usePriceAdjustments;