import classes from "./scss/CustomizeModal.module.scss";
import { FC, useEffect, useMemo, useState } from 'react';
import { ChoicesById, CustomDetailType, SectionChoicesById } from '../../Types/Types';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { formatPrice } from '../../common/price/price';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import useTheme from '@mui/material/styles/useTheme';
import ChoiceAccordion from "./ChoiceAccordion";
import { AnyOfSection } from "./CustomizationSection";
import { useCustomization } from "./hooks/useCustomization";


interface RadioButtonsGroupProps {
  radioOnChange: (selected?: string) => void;
  message: string;
  choices: CustomDetailType[];
  isNested: boolean,
  choicesById: ChoicesById,
  sectionChoicesById: SectionChoicesById,
  customizationContext: ReturnType<typeof useCustomization>,
}


const RadioButtonsGroup: FC<RadioButtonsGroupProps> = (props) => {
  const { radioOnChange, choicesById, sectionChoicesById, customizationContext, message, choices, isNested } = props;
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const theme = useTheme();

  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    choices.forEach((choice, i) => {
      if (choice.value === 1) {
        setValue(i);
      }
    });
  }, [choices])



  const RadioStyle = (i: number) => {
    if (value === i)
      return { color: theme.palette.primary.main }
  }


  return (
    <FormControl component="fieldset" style={{ width: '100%', fontSize: '12px' }}>
      <RadioGroup
        aria-label={message}
        name={message}
        value={value}
      >
        {choices.map((choice, i) => {
          const isPriceZero = choice.price === 0;

          const formattedChoicePrice = isPriceZero ? "" : formatPrice(choice.price);
          const choiceSoldout = choice.soldout;

          const choiceValues = choicesById[choice.id!];
          const currentSelected = choiceValues.value;

          const RadioControlElem = <Radio size="small"
            disabled={choiceSoldout}
            onChange={(event, checked) => {
              const getSelected = parseInt(event.target.value)
              setValue(getSelected);
              radioOnChange(choice.id);
            }}
            style={RadioStyle(i)} />;

          const displayElement = <div key={i} className={classes.customize__menu_selectRadio}>
            <FormControlLabel key={i}
              value={i}
              control={RadioControlElem}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              label={
                <Typography
                  fontSize="14px !important"
                  className={classes.customize__typography}
                >
                  {choice.name}
                </Typography>
              }
            />
            <Typography fontSize="14px !important">{formattedChoicePrice}
            </Typography>
          </div>;

          const noNestedChoices = !choice?.nestedChoices?.length;
          if (noNestedChoices || !currentSelected) {
            return displayElement
          }

          if (isNested) return;
          return (
            <ChoiceAccordion
              elevation={0}
              disableGutters
              key={`${choice.name}_${i}`}
              sx={{
                width: "100%",
              }}
              displayElement={displayElement}
              defaultExpanded={!!currentSelected}
            >
              {choice.nestedChoices.map(section =>
                <AnyOfSection
                  section={section}
                  sectionChoicesById={sectionChoicesById}
                  customizationContext={customizationContext}
                  isNested
                />)}
            </ChoiceAccordion>
          );

        })}
      </RadioGroup>
    </FormControl>
  );
}


export default RadioButtonsGroup;




