import { useMemo } from "react";
import { currentTimeOptions, getPreOrderDates } from "../../../common/date";
import { StoreOrderingState, orderTypeMap } from "../../../constants";
import { useTypedSelector } from "../../../hooks/useTypedSelector";


const useDateTimeOptions = () => {

    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const finalOrder = useTypedSelector((state) => state.order.finalOrder);
    const orderType = finalOrder.orderType;

    const isPreOrderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
    const isOrderTypePreOrder = orderType === orderTypeMap.preorder;
    const isDeliveryOrder = orderType === "delivery";

    const today = new Date().getDate();


    const selectedPrepDate = useMemo(() => {
        const prepDate = finalOrder?.requestedPrepDateTime;
        if (!prepDate) return undefined;

        return new Date(prepDate);
    }, [finalOrder?.requestedPrepDateTime]);

    const dateOptions = useMemo(() => getPreOrderDates(businessInfo?.workingHours), [businessInfo?.workingHours]);
    const minPrepTime = useMemo(() => {
        const entry = Object.entries(orderTypeMap).find(x => x[1] === orderType);
        const diningOption = entry?.[0];
        const prepTime = businessInfo?.diningOption[diningOption + "PrepTimeMinimum"];

        if (!prepTime || prepTime === 0) {
            const deliveryPrepTimeMinimum = businessInfo?.deliveryPrepTimeMinimum ?? 0;
            return deliveryPrepTimeMinimum;
        }

        return prepTime;
    }, [orderType, businessInfo?.diningOption, businessInfo?.deliveryPrepTimeMinimum]);

    const dateTimeOptions = useMemo(() => {
        const currTimeOptions = currentTimeOptions(dateOptions, isPreOrderOnly, finalOrder.orderType ?? "take-out", minPrepTime)
        return currTimeOptions;
    }, [dateOptions, finalOrder.orderType]);

    const selectedDateOptionIndex = useMemo(() => {
        return dateTimeOptions.findIndex((dateTimeOption) => {
            if (selectedPrepDate) {
                return dateTimeOption.date.getDate() === selectedPrepDate.getDate();
            }

            if (!isOrderTypePreOrder) {
                return dateTimeOption.date.getDate() === today;
            }

            return false;
        })
    }, [selectedPrepDate, isOrderTypePreOrder, dateTimeOptions, dateOptions]);

    const selectedDateTimeOption = dateTimeOptions[selectedDateOptionIndex];
    const timeOptions = selectedDateTimeOption?.timeOptions ?? [];

    const selectedTimeOptionIndex = useMemo(() => {
        const index = timeOptions.findIndex((timeOption) => {
            if (selectedPrepDate) {
                return (
                    timeOption.minute === selectedPrepDate.getMinutes() &&
                    timeOption.hour === selectedPrepDate.getHours()
                );
            } else {
                return false;
            }
        });

        return index;
    }, [selectedPrepDate, timeOptions, selectedDateOptionIndex])

    return { selectedDateOptionIndex, selectedTimeOptionIndex, dateTimeOptions, timeOptions, selectedPrepDate }
}

export default useDateTimeOptions;