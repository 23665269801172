import { useParams } from "react-router";
import { OrderMode, phoneInvalidErrorMessage, phoneRequiredMessage, smsNotificationReqKey, userPhoneCookieKey } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useActions } from "../../hooks/useActions";
import { useEffect, useMemo } from "react";
import { getCookie, setCookie } from "../../common/cookies";
import { getFormattedPhoneNumber, normalizeFormattedPhoneNumber } from "../../common/phoneUtils";
import { getOrderMode } from "../../common/app";
import { CheckoutFormStatus } from "../../Types/Types";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import CheckboxLabels from "../CustomizeModal/CheckboxButton";
import PhoneIcon from '@material-ui/icons/Phone';
import classes from "./Checkout.module.scss";

interface PhoneFieldComboProps {
}

const PhoneFieldCombo = (props: PhoneFieldComboProps) => {
    const { } = props;
    const { setUserNumber, updateCheckoutFormStatus, } = useActions();

    const finalOrder = useTypedSelector((state) => state.order.finalOrder);

    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const { checkoutFormStatus } = useTypedSelector((state) => state.appLocalStatus);


    const handleUserPhoneChange = (event) => {
        const currentValue = event.target.value as string;
        updateCheckoutFormStatus(CheckoutFormStatus.standby);
        const phoneNum = normalizeFormattedPhoneNumber(currentValue);
        setUserNumber(phoneNum);
    };

    useEffect(() => {
        const savedUserPhone = getCookie(userPhoneCookieKey);
        if (savedUserPhone) {
            setUserNumber(savedUserPhone);
        }
    }, []);

    const phoneDisplayValue = useMemo(() => {
        const { userPhoneNumber } = finalOrder;
        return getFormattedPhoneNumber(userPhoneNumber);
    }, [finalOrder.userPhoneNumber]);

    const isDeliveryOrder = finalOrder.orderType === "delivery";
    const isCustomerContactRequired = useMemo(() => {
        if (isDeliveryOrder) return true;
        return businessInfo?.customerContactInfo === "required";
    }, [businessInfo?.customerContactInfo, isDeliveryOrder]);

    const isMissingField = checkoutFormStatus === CheckoutFormStatus.missingFields;
    const isPhoneMissing = isMissingField && !phoneDisplayValue;
    const hasError = isCustomerContactRequired && isPhoneMissing;

    const PhoneMsg = useMemo(() => {
        if (hasError) {
            return phoneInvalidErrorMessage;
        }

        if (isDeliveryOrder && isCustomerContactRequired) {
            return phoneRequiredMessage;
        }
    }, [hasError, isDeliveryOrder, isCustomerContactRequired])

    return <>

        <TextField
            type="text"
            autoComplete="on"
            label="Mobile"
            size="small"
            className={classes.textFieldHalf}
            required={isCustomerContactRequired}
            error={hasError}
            helperText={PhoneMsg}
            placeholder={isCustomerContactRequired ? "Required" : "Optional"}
            onChange={handleUserPhoneChange}
            inputProps={{ maxLength: 10 }}
            value={phoneDisplayValue}
            InputProps={{
                startAdornment:
                    <InputAdornment position="start">
                        <PhoneIcon />
                    </InputAdornment>
            }}
        />
    </>
}


const SendSmsCheckbox = (props) => {

    const { setSmsNotificationRequested, } = useActions();

    const { viewMode } = useParams<IPublicProfileRouteParams>();
    const appViewMode = getOrderMode(viewMode);
    const isKioskViewMode = appViewMode === OrderMode.kiosk;

    const finalOrder = useTypedSelector((state) => state.order.finalOrder);
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);

    const isDeliveryOrder = finalOrder.orderType === "delivery";
    const isCustomerContactRequired = useMemo(() => {
        if (isDeliveryOrder) return true;
        return businessInfo?.customerContactInfo === "required";
    }, [businessInfo?.customerContactInfo, isDeliveryOrder]);

    if (!isCustomerContactRequired || isDeliveryOrder) {
        return null;
    }

    return <CheckboxLabels
        isChecked={finalOrder.smsNotificationRequested}
        controlLabel="Send me SMS when confirmed and ready"
        checkOnChange={(newVal) => {
            if (isKioskViewMode === false) {
                const stringified = JSON.stringify(newVal);
                setCookie(smsNotificationReqKey, stringified);
            }
            setSmsNotificationRequested(newVal);
        }} //update in redux store
        boldLabel
    />
};



export { SendSmsCheckbox };
export default PhoneFieldCombo;