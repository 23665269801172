import "./ProgressBarOverwrite.scss";
import axios from "axios";
import classes from "./OrderTracking.module.scss";
import EmailReceipt from "./EmailReceipt";
import BrowserBackModal from "../BrowserBackModal/BrowserBackModal";
import CancelOrderModal from "../CancelOrderModal/CancelOrderModal";
import Snackbar from "../Snackbar/Snackbar";
import PageNotFound from "../../pages/notFound/pageNotFound";
import PhoneInput from "./PhoneInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { useActions } from "../../hooks/useActions";
import { Link, useHistory } from "react-router-dom";
import { ITrackingRouteParams } from "../Feedback/Feedback";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {
  RequestStatus,
  SubmitToServerIAmHere,
  CancelOrder,
  CancelNewOrder,
} from "../../api";
import { baseUrl, trackingReqInterval } from "../../serverConfig";
import { FinalOrderPayload, OrderStatus } from "../../Types/Types";
import {
  cancelEnableElapsedMinutes,
  cancelNewOrderError,
  orderTypeMap,
  StoreOrderingState as StoreOrderingState,
} from "../../constants";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import useExitPrompt from "../../hooks/useExitPrompt";

// material ui imports
import { useTheme } from "@mui/material/styles";
import LinearWithValueLabel from "../ProgressBar/LinearProgressBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RejectedIcon from "../../assets/icons/RejectedIcon";

enum StatusText {
  delivery = "On the way...",
  receiving = "Receiving...",
  verifyPhone = "Verifying phone number...",
  checking = "Checking...",
  preparing = "Preparing...",
  rejected = "Order Rejected",
  cancelled = "Order Cancelled",
  ready = "Ready",
  pickedUp = "Picked Up",
  feedback = "Feedback",
  preOrderSent = "Pre-Order Sent",
  preOrderConfirmed = "Pre-Order Confirmed",
}

enum StatusCode {
  verifyPhone = "p",

  // auto-reloads START
  receiving = "n",
  checking = "v",
  preparing = "c",
  // auto-reloads END

  delivery = "d",
  rejected = "r",
  cancelled = "L",
  ready = "s",
  pickedUp = "a",
  feedback = "f",
}

const TrackingPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const { path, url } = useRouteMatch();
  const { orderNumber, userId } = useParams<ITrackingRouteParams>();
  const order = useTypedSelector((state) => state.order);
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const finalOrder = useTypedSelector((state) => state.order.finalOrder);
  const { orderTypeFromTracking } = useTypedSelector(
    (state) => state.appLocalStatus
  );
  const [pickUpDateTime, setPickUpDateTime] = useState("");
  const {
    changeOrder,
    setTipPercentage,
    setCompleteFinalOrder,
    fetchAndStoreBizInfo,
    setUserCredentials,
    emptyCart,
    setOrderTypeFromTracking,
    setEnableBrowserBackModal,
    setShowBrowserBackModal,
  } = useActions();
  const [percentage, setPercentage] = useState(0);
  const [isAutoReload, setAutoReload] = useState(true);
  const [status, setStatus] = useState(StatusText.receiving);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(
    "Your order is being processed, please refresh after 30 seconds"
  );
  const [lastUpdated, setLastUpdated] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [isRejected, setIsRejected] = useState(false);
  const [showReadyTime, setShowReadyTime] = useState(false);
  const [showEnterEmail, setShowEnterEmail] = useState(true);
  const [showFooter, setShowFooter] = useState(false);
  const [businessPath, setBusinessPath] = useState<string>();
  const [currentFinalOrder, setCurrentFinalOrder] = useState(finalOrder);
  const [arrived, setArrived] = useState(false);
  const [userIdFromServer, setUserIdFromServer] = useState("0");
  const [serviceProviderUserId, setServiceProviderUserId] = useState("");
  const [hasRewardsProgram, setHasRewardsProgram] = useState(true);
  const [isTrackingFailed, setTrackingFailed] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [canCancelNewOrder, setCanCancelNewOrder] = useState(false);
  const [isNewOrderRejectionError, setIsNewOrderRejectionError] =
    useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [statusCode, setStatusCode] = useState<StatusCode>();
  const [orderId, setOrderId] = useState(0);

  useExitPrompt();

  let firstLoading = true;

  // TODO: progress/loading indicator for first time loading page.
  let interval;

  useEffect(() => {
    setShowBrowserBackModal(false);
    getStatus();
    interval = setInterval(async () => {
      getStatus();
    }, trackingReqInterval);

    const verifyCustomerPhone = async () => {
      const hasUserId = !!userId;
      if (!hasUserId) return;

      try {
        const url = `${baseUrl}/rest/orderAction/orderStatusLink`;
        await axios.get(`${url}/${orderNumber}`);
      } catch (error) {}
    };

    verifyCustomerPhone();
    setUserCredentials();
  }, []);

  useEffect(() => {
    console.log("Order Number: ", orderNumber);

    if (!isAutoReload) {
      clearInterval(interval);
    }

    window.history.pushState(null, "", `/order/${orderNumber}`);
    setEnableBrowserBackModal(true);

    return () => {
      setEnableBrowserBackModal(false);
      clearInterval(interval);
    };
  }, [isAutoReload, interval]);

  const handleBrowserBack = () => {
    window.history.pushState(null, "", `/order/${orderNumber}`);
    setShowBrowserBackModal(true);
  };

  const getStatus = async () => {
    const orderInfoRes = await RequestStatus(orderNumber);

    const orderInfo = orderInfoRes as OrderStatus;
    const err = orderInfoRes as { message: string };
    if (err.message || (orderInfoRes as any) === "error") {
      history.replace("/404");
      return;
    }

    let orderPayload: FinalOrderPayload;
    try {
      orderPayload = JSON.parse(orderInfo?.orderPayload);
    } catch (error) {
      if (firstLoading === true) {
        setTrackingFailed(true);
        setAutoReload(false);
        setEnableBrowserBackModal(false);
      }

      return;
    }
    if (firstLoading) {
      console.log("IsFirstLoading: ", firstLoading);

      await fetchAndStoreBizInfo(orderInfo.businessPath);
      setIsFirstLoading(false);
      firstLoading = false;
    }

    console.log("createJson: ", orderPayload);
    console.log("ORDER: ", order);

    if (orderPayload.userId) {
      setUserIdFromServer(orderPayload.userId);
    }

    setBusinessName(orderInfo.businessName);
    document.title = orderInfo.businessName;
    setOrderId(orderInfo.orderNumber);

    if (orderInfo.orderDueTime) {
      setPickUpDateTime(orderInfo.orderDueTime);
    } else {
      setPickUpDateTime("");
    }

    const isPreOrder = orderPayload.orderType === orderTypeMap.preorder;
    const orderStatus = orderInfo.orderStatus as StatusCode;
    decodeStatus(orderStatus, isPreOrder, orderInfo.storeOrderingStatus);

    if (orderInfo.storeOpen && orderStatus === StatusCode.receiving) {
      const lastUpdate = new Date(orderInfo.orderActionDateAndTime);
      const now = new Date();
      const elapsedTime = now.getTime() - lastUpdate.getTime();
      if (elapsedTime >= cancelEnableElapsedMinutes) setCanCancelNewOrder(true);
    }

    setHasRewardsProgram(orderInfo.stampCardActive !== "no");
    setLastUpdated(orderInfo.lastUpdate);
    setBusinessPath(orderInfo.businessPath);
    setServiceProviderUserId(orderPayload.serviceProviderUserId);

    // setCompleteFinalOrder({ ...createJson, finalOrderItems: [...order.order] })
    setCompleteFinalOrder({
      ...orderPayload,
      finalOrderItems: [...orderPayload.finalOrderItems],
    });
    setOrderTypeFromTracking(orderPayload.orderType);
    setCurrentFinalOrder(order.finalOrder);

    if (order.order.length === 0 && orderPayload.selectedTipPercentage) {
      setTipPercentage(orderPayload.selectedTipPercentage.toString());
    }

    if (orderInfo.rejectionReason) {
      setMsg(orderInfo.rejectionReason);
    } else {
      setMsg(orderInfo.orderPrepTime);
    }

    if (process.env.NODE_ENV === "development") {
      // decodeStatus(StatusCode.preparing);
      // setAutoReload(orderInfo.storeOpen);
      // setIsPreOrder(true);
      // setUserIdFromServer(1);
    }
  };

  const decodeStatus = (
    code: StatusCode,
    isPreOrder: boolean,
    storeOrderingState: StoreOrderingState
  ) => {
    setStatusCode(code);
    if (code === StatusCode.verifyPhone) {
      setStatus(StatusText.verifyPhone);
      setPercentage(11);
      setIsRejected(false);
      setShowReadyTime(false);
      setShowEnterEmail(false);
      setAutoReload(true);
      setShowFooter(false);
    } else if (code === StatusCode.receiving) {
      if (storeOrderingState === StoreOrderingState.preOrdersOnly) {
        setStatus(StatusText.preOrderSent);
        setAutoReload(false);
      } else {
        setStatus(StatusText.receiving);
        setAutoReload(true);
      }

      setPercentage(22);
      setIsRejected(false);
      setShowReadyTime(false);
      setShowEnterEmail(false);
      setShowFooter(false);
    } else if (code === StatusCode.checking) {
      setStatus(StatusText.checking);
      setPercentage(33);
      setIsRejected(false);
      setShowReadyTime(false);
      setShowEnterEmail(false);
      setAutoReload(true);
      setShowFooter(false);
    } else if (code === StatusCode.preparing) {
      if (isPreOrder) {
        setStatus(StatusText.preOrderConfirmed);
        setAutoReload(false);
      } else {
        setStatus(StatusText.preparing);
        setAutoReload(true);
      }

      setPercentage(66);
      setIsRejected(false);
      setShowReadyTime(true);
      setShowEnterEmail(true);
      setShowFooter(false);
    } else if (code === StatusCode.rejected) {
      setStatus(StatusText.rejected);
      // setPercentage(33);
      setIsRejected(true);
      setShowReadyTime(false);
      setShowEnterEmail(false);
      setAutoReload(false);
      setShowFooter(false);
    } else if (code === StatusCode.cancelled) {
      setStatus(StatusText.cancelled);
      // setPercentage(33);
      setIsRejected(false);
      setShowReadyTime(false);
      setShowEnterEmail(false);
      setAutoReload(false);
      setShowFooter(false);
    } else if (code === StatusCode.ready) {
      setStatus(StatusText.ready);
      setPercentage(100);
      setIsRejected(false);
      setShowReadyTime(true);
      setShowEnterEmail(true);
      setAutoReload(false);
      setShowFooter(true);
    } else if (code === StatusCode.pickedUp) {
      setStatus(StatusText.pickedUp);
      setPercentage(100);
      setIsRejected(false);
      setShowReadyTime(false);
      setShowEnterEmail(true);
      setAutoReload(false);
      setShowFooter(true);
    } else if (code === StatusCode.feedback) {
      setStatus(StatusText.feedback);
      setPercentage(100);
      setIsRejected(false);
      setShowReadyTime(false);
      setShowEnterEmail(true);
      setAutoReload(false);
      setShowFooter(false);
    }
  };
  console.log(status);
  const change = () => {
    console.log("CURRENT_FINAL: ", currentFinalOrder);
    changeOrder(order.finalOrder);
    history.push(`/${businessPath}`);
  };

  const cancel = () => setShowCancelOrderModal(true);

  const goToFeedback = () =>
    history.push(`/${businessPath}/feedback/${orderNumber}`);

  const SendIAmHere = useCallback(async () => {
    try {
      const { status } = await SubmitToServerIAmHere(orderNumber);
      if (status === 200) setArrived(true);
    } catch (error) {
      setSnackbarOpen(true);
    }
  }, []);

  const showRecommendBtn =
    statusCode === StatusCode.ready ||
    statusCode === StatusCode.feedback ||
    statusCode === StatusCode.pickedUp;

  const ShowIAmHereBtn = useMemo(() => {
    if (
      order.finalOrder?.orderType === "delivery" ||
      statusCode === StatusCode.delivery
    )
      return false;

    return statusCode === StatusCode.ready;
  }, [statusCode, order?.finalOrder?.orderType]);

  if (isLoading) return <Loader />;
  return (
    <>
      {isTrackingFailed ? (
        <PageNotFound />
      ) : isFirstLoading ? (
        <Loader />
      ) : (
        <>
          <Snackbar
            open={snackbarOpen}
            setOpen={setSnackbarOpen}
            message={
              isNewOrderRejectionError
                ? cancelNewOrderError
                : "Internet issue, please try again."
            }
            severity="error"
          />

          <Header
            returnUrl={url}
            businessId={businessPath}
            accountRouteToBaseUrl
          />
          <div
            className={classes.body}
            style={{
              backgroundColor: theme.palette['light05'],
            }}
          >
            <Box
              onClick={(e) => handleBrowserBack()}
              className={classes.backBtn}
              sx={{
                background: theme.palette.primary.main,
                width: "100%",
              }}
            >
              <ArrowBackIcon sx={{ color: "#fff" }} />
              <span
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 400,
                }}
              >
                Back to {businessName} menu
              </span>
            </Box>

            <BrowserBackModal businessPath={businessPath} needReload={true} />
            <CancelOrderModal
              isOpen={showCancelOrderModal}
              setIsOpen={setShowCancelOrderModal}
              businessPath={businessPath}
              onConfirm={async () => {
                setIsLoading(true);
                if (canCancelNewOrder) {
                  try {
                    const resp = await CancelNewOrder(
                      orderNumber,
                      businessInfo?.businessId
                    );
                    if (resp.data === "success") {
                      getStatus();
                      setShowCancelOrderModal(false);
                    }

                    if (resp.data === "error") {
                      setIsNewOrderRejectionError(true);
                      setSnackbarOpen(true);
                    }
                  } catch (error) {
                    setSnackbarOpen(true);
                  } finally {
                    setIsLoading(false);
                  }
                } else {
                  await CancelOrder(orderNumber, serviceProviderUserId);
                  emptyCart();
                  history.push(`/${businessPath}`);
                }
              }}
            />

            <Box
              className={classes.wrapper}
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {showReadyTime && (
                  <h3 className={classes.readyTime}>{pickUpDateTime}</h3>
                )}
                {status !== StatusText.feedback && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      margin: "8px",
                      flexDirection:
                        percentage === 100 ||
                        status === StatusText.preOrderConfirmed
                          ? "row"
                          : "column",
                      gap: "4rem",
                      marginTop: "3rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "2rem",
                        fontWeight: 500,
                        color: "#1D1B20",
                      }}
                    >
                      <>{status} </>
                      <>
                        {percentage === 100 && (
                          <CheckCircleOutlineOutlinedIcon
                            sx={{
                              color: '#2B8000',
                              fontSize: 40,
                            }}
                          />
                        )}
                        {(percentage === 0 || isRejected) && <RejectedIcon />}
                      </>
                    </Typography>
                      {
                        (status === StatusText.preparing || status === StatusText.receiving || status === StatusText.checking) && (
                          <LinearWithValueLabel prog={percentage} />
                        )
                      }
                    {status === StatusText.preOrderConfirmed && (
                      <>
                        <CheckCircleOutlineOutlinedIcon
                          sx={{
                            color: '#2B8000',
                            fontSize: 40,
                          }}
                        />
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  margin: "8px",
                }}
              >
                {status === StatusText.preparing && (
                  
                  <Typography
                    className={classes.readyIn}
                    fontSize="1rem"
                    fontWeight={600}
                  >
                    Serving By {!arrived && `: ${msg}`}
                  </Typography>
                )}
                {status !== StatusText.preparing && status !== StatusText.feedback && (
                  <Typography
                    className={classes.readyIn}
                    sx={{
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
                {
                  status === StatusText.feedback && (
                    <Typography
                      className={classes.readyIn}
                      sx={{
                        fontWeight: 600,
                        fontSize: "2rem",
                      }}
                    >
                      {msg}
                    </Typography>
                  )
                }
                
                {arrived && (
                  <Typography>Pick your item from front desk.</Typography>
                )}
                <Typography className={classes.order} fontSize={"12px"}>
                  Order {orderNumber}
                </Typography>

                {status === StatusText.verifyPhone && (
                  <PhoneInput getStatus={getStatus} orderNumber={orderId} />
                )}

                {lastUpdated && (
                  <div className={classes.time}>
                    <Typography variant="body1" fontSize={"12px"}>
                      Last updated {lastUpdated}
                    </Typography>
                  </div>
                )}
              </Box>

              {isRejected ? (
                <div className={classes.buttons}>
                  <Button
                    onClick={change}
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      borderRadius: "56px",
                      backgroundColor: "transparent",
                      textTransform: "none",
                      cursor: "pointer",
                      "& span": { borderBottom: "none !important" },
                    }}
                  >
                    <Typography fontSize="20px" fontWeight={500}>
                      Change
                    </Typography>
                  </Button>
                  <Button
                    onClick={cancel}
                    sx={{
                      color: "primary.main",
                      width: "100%",
                      borderRadius: "56px",
                      backgroundColor: "transparent",
                      textTransform: "none",
                      borderShadow: "none",
                      cursor: "pointer",
                      borderBottom: "0px",
                      "& span": { borderBottom: "none !important" },
                    }}
                  >
                    <Typography fontSize="20px" fontWeight={500}>
                      Cancel
                    </Typography>
                  </Button>
                </div>
              ) : (
                <>
                  {showEnterEmail ? (
                    <EmailReceipt
                      orderNumber={orderNumber}
                      hasRewardsProgram={hasRewardsProgram}
                      userId={userIdFromServer}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
              {canCancelNewOrder && status === StatusText.receiving && (
                <Button
                  onClick={(e) => {
                    setShowCancelOrderModal(true);
                  }}
                  sx={{
                    border: "2px solid" + theme.palette.primary.main,
                    color: "primary.main",
                    width: "100%",
                    borderRadius: "56px",
                  }}
                >
                  <Typography variant="body1">Cancel Order</Typography>
                </Button>
              )}
              {ShowIAmHereBtn && (
                <>
                  <Box
                    className={classes.imHere}
                    sx={{ display: arrived ? "none" : "" }}
                  >
                    <Button
                      onClick={SendIAmHere}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "#fff",
                        borderRadius: "56px",
                        padding: "1rem 0",
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="500">
                        I am Here
                      </Typography>
                    </Button>
                  </Box>
                </>
              )}

              {showFooter && (
                <Button
                  onClick={goToFeedback}
                  sx={{
                    color: theme.palette.primary.main,
                    borderRadius: "100px",
                    padding: "10px 0",
                    textTransform: "none",
                    border: "2px solid" + theme.palette.primary.main,
                    "&:hover": {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <Typography fontSize="20px" fontWeight="500">
                    {"Rate Us!" || StatusText.feedback}
                  </Typography>
                </Button>
              )}
              {showRecommendBtn && (
                <Box>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      width: "100%",
                    }}
                  >
                    <Button
                      component={Link}
                      to={`/${businessPath}/recommend`}
                      sx={{
                        color: theme.palette.primary.main,
                        width: "100%",
                        borderRadius: "100px",
                        padding: "10px 0",
                        textTransform: "none",
                        "&:hover": {

                          color: theme.palette.primary.main,
                        },
                        border: "2px solid" + theme.palette.primary.main,
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="500">
                        {"Recommend Us" || StatusText.feedback}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default TrackingPage;
