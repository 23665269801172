import classes from "./Checkout.module.scss";
import { useParams } from "react-router";
import { nameMissingErrorMessage, userCustomerNameCookieKey } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useActions } from "../../hooks/useActions";
import { useEffect } from "react";
import { getCookie, setCookie } from "../../common/cookies";
import { CheckoutFormStatus } from "../../Types/Types";
import { InputAdornment, TextField } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';

interface NameFieldComboProps {
}

const NameFieldCombo = (props: NameFieldComboProps) => {
    const { } = props;
    const { setUserName, updateCheckoutFormStatus } = useActions();

    useParams<IPublicProfileRouteParams>();
    const finalOrder = useTypedSelector((state) => state.order.finalOrder);

    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const { checkoutFormStatus } = useTypedSelector((state) => state.appLocalStatus);

    useEffect(() => {
        const savedCustomerName = getCookie(userCustomerNameCookieKey);
        if (savedCustomerName) {
            setUserName(savedCustomerName);
        }
    }, []);

    const isMissingField = checkoutFormStatus === CheckoutFormStatus.missingFields;
    const isNameFieldMissing = isMissingField && !finalOrder.customerName;
    const hasError = isNameFieldMissing && businessInfo?.customerNameRequired;

    return <>

        <TextField
            type="text"
            name="customerName"
            autoComplete="off"
            label="Name"
            size="small"
            className={classes.textFieldHalf}
            error={hasError}
            onBlur={(e) => {
                const customerName = e.target.value;
                if (customerName.trim()) {
                    setCookie(userCustomerNameCookieKey, customerName);
                }
            }}
            placeholder={businessInfo?.customerNameRequired ? "Required" : "Optional"}
            required={businessInfo?.customerNameRequired ?? false}
            helperText={hasError && nameMissingErrorMessage}
            value={finalOrder.customerName ?? ""}
            inputProps={{ maxLength: 50 }}
            InputProps={{
                startAdornment:
                    <InputAdornment position="start">
                        <PersonIcon />
                    </InputAdornment>
            }}
            onChange={(e) => {
                const customerName = e.target.value;
                const onlyAlphabeticalChars = customerName.replace(
                    /\d/,
                    ""
                );

                setUserName(onlyAlphabeticalChars);
                updateCheckoutFormStatus(CheckoutFormStatus.standby);
            }}
        />


    </>
}


export default NameFieldCombo;