import { DISCOUNT_TYPE, BusinessInfo } from './../Types/Types';
import { OptionType } from "../constants";
import { FinalOrderPayload } from "../Types/Types";
import { calculateItemTotal } from "./price/price"

export const calculateTax = (subTotal: number, noTaxSubtotal: number) => {
  return (subTotal - noTaxSubtotal) * 5 / 100;
}

const noTaxOptionItemTypes = [
  OptionType.giftCard,
]

export const calculateNoTaxSubTotal = (finalOrder: FinalOrderPayload) => {
  const subTotal = finalOrder.finalOrderItems.reduce((acc, curr, currInd, array) => {
    if (noTaxOptionItemTypes.includes(curr.type ?? OptionType.all))
      return acc + calculateItemTotal(curr);
    else
      return acc;
  }, 0);

  return subTotal;
}

export const calculatePaperBagFee = (finalOrder) => {
  if (!finalOrder.paperBagFee || !finalOrder.paperBagCount)
    return 0;
  else
    return finalOrder.paperBagFee * finalOrder.paperBagCount;
}

export const checkGiftCardPurchasable = (finalOrder: FinalOrderPayload, businessInfo: BusinessInfo | null) => {
  const creditDiscount = businessInfo?.overallDiscounts.find(dc => dc.discountType === DISCOUNT_TYPE.credit);
  const hasCredit = !!creditDiscount && creditDiscount.value > 0;
  const cartContainsGiftCardItem = finalOrder.finalOrderItems.some(item => item.type === OptionType.giftCard);
  const giftCardPurchasable = !hasCredit || !cartContainsGiftCardItem;
  return giftCardPurchasable;
}
