import classes from "./Checkout.module.scss";
import { useParams } from "react-router";
import { OptionType, StoreOrderingState, orderTypeMap } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useActions } from "../../hooks/useActions";
import { useCallback, useEffect, useMemo } from "react";
import { formatPrice, isNumber } from "../../common/price/price";
import { FormControl, InputLabel, Select } from "@material-ui/core";

const noPaperBagOrderTypes = [orderTypeMap.delivery, orderTypeMap.dinein];

interface PaperbagDropdownProps {}

const paperPagOptions = (paperBagFee: number | undefined) => {
  const fee = paperBagFee ?? 0;
  const options = [
    <option key={0} value={0}>
      Will bring my own bag
    </option>,
  ];
  for (let i = 1; i < 5; i++) {
    const calcFee = fee * i;
    options.push(
      <option key={calcFee} value={i}>
        {i} bag ({formatPrice(calcFee)})
      </option>
    );
  }

  return options;
};

const PaperbagDropdown = (props: PaperbagDropdownProps) => {
  const { setPaperBagCount } = useActions();

  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const finalOrder = useTypedSelector((state) => state.order.finalOrder);
  const { prevOrderType, prevPrevOrderType } = useTypedSelector(
    (state) => state.appLocalStatus
  );
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);

  const isPreOrderOnly =
    businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;

  const hasOnlyGiftCardsInCart = useMemo(() => {
    return !finalOrder.finalOrderItems.find(
      (x) => x.type !== OptionType.giftCard
    );
  }, [finalOrder.finalOrderItems]);

  const showPaperBagDropdown = useMemo(() => {
    if (hasOnlyGiftCardsInCart) return false;
    if (isPreOrderOnly) return false;
    const isTableViewMode = isNumber(viewMode);
    if (isTableViewMode) return false;
    if (finalOrder.orderType === "delivery") return false;

    return !!businessInfo?.paperBagFee && businessInfo.paperBagFee > 0;
  }, [
    viewMode,
    isPreOrderOnly,
    hasOnlyGiftCardsInCart,
    businessInfo?.paperBagFee,
    finalOrder.orderType,
  ]);

  const updateBagCount = useCallback(
    (newOrderType) => {
      if (noPaperBagOrderTypes.includes(newOrderType) || hasOnlyGiftCardsInCart)
        setPaperBagCount(0);
      else setPaperBagCount(1);
    },
    [hasOnlyGiftCardsInCart, prevOrderType, prevPrevOrderType]
  );

  useEffect(() => {
    if (prevOrderType !== prevPrevOrderType) {
      updateBagCount(finalOrder.orderType);
    }
  }, [finalOrder.orderType, prevOrderType, prevPrevOrderType]);

  if (!showPaperBagDropdown) return null;

  return (
    <div className={classes.form_paperBag}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Paper Bag</InputLabel>
        <Select
          native
          id="paperBag"
          name="paperBag"
          style={{ width: "190px" }}
          value={finalOrder.paperBagCount}
          label="Paper Bag"
          onChange={(e) => {
            const val = e.target.value;
            const num = Number(val);
            if (isNaN(num)) {
              console.error("Cannot parse non-digit option value.");
              return;
            }
            setPaperBagCount(num);
          }}
        >
          {paperPagOptions(businessInfo?.paperBagFee)}
        </Select>
      </FormControl>
    </div>
  );
};

export default PaperbagDropdown;
