import { useState } from 'react';
import { appendBaseUrlToImage } from '../common/image';
import { calculateCustomizationsAmt, calculatePromotionsAmount } from '../common/price/price';
import { useTypedSelector } from './useTypedSelector';
import { DISCOUNT_VALUE_TYPE, MenuItem } from '../Types/Types';

export const useItemDetails = (itemArg: MenuItem) => {

  const [item] = useState<MenuItem>(itemArg);
  
  const orders = useTypedSelector((state) => state.order);
  const businessInfo = useTypedSelector(store => store.businessInfo?.businessInfo);

  const image = appendBaseUrlToImage(item.productImage || item.image);

  const customizationAmt = calculateCustomizationsAmt(item);
  const discountAmt = calculatePromotionsAmount(item.discountRule, item.price);
  
  const fullPrice = item.price + customizationAmt;
  const discountedPrice = item.price + customizationAmt - discountAmt;

  const isBOGO = DISCOUNT_VALUE_TYPE.bogo === item.discountRule?.type;
  let qty = isBOGO ? Math.floor(item.count / 2) + item.count % 2 : item.count;

  const itemTotal = fullPrice * qty;
  const discountedItemTotal = discountedPrice * qty;

  return { 
    image,
    customizationAmt,

    discountAmt,
    discountedPrice: discountedItemTotal,
    fullPrice, itemTotal,
   };
};
