import {
  BusinessDiscount, DiscountRule, DISCOUNT_VALUE_TYPE,
  MenuOption, orderItemType, FinalOrderPayload, preItemType,
  MenuItem
} from "../../Types/Types";

export const isNumber = (inputStr) => {
  if (!inputStr) return false;
  if (isNaN(Number(inputStr))) return false;
  return true;
}

export const calculateItemFullPrice = (item: MenuOption | orderItemType | preItemType) => {
  const discountedAmt = calculatePromotionsAmount(item.discountRule, item.price);
  const customizationAmt = calculateCustomizationsAmt(item);
  return item.price + customizationAmt - discountedAmt;
}

export const calculateItemTotal = (item: MenuItem) => {
  const isBOGO = DISCOUNT_VALUE_TYPE.bogo === item.discountRule?.type;
  let qty = isBOGO ? Math.floor(item.count / 2) + item.count % 2 : item.count;
  return calculateItemFullPrice(item) * qty;
}

export const calculatePromotionsAmount = (discountRule: DiscountRule, price: number) => {
  if (!discountRule) return 0;

  switch (discountRule.type) {
    case DISCOUNT_VALUE_TYPE.percentage:
      const percentAmt = price * discountRule.value * 0.01;
      return floorPrice(percentAmt);
    case DISCOUNT_VALUE_TYPE.amount:
      return discountRule.value;
    default:
      return 0;
  }
}

export const calculateCustomizationsAmt = (option: MenuOption | orderItemType | preItemType) => {
  let customizationAmount = 0;
  let customization;
  const asMenuOption = option as MenuOption;
  if (asMenuOption.customization) {
    customization = asMenuOption.customization;
  }
  const asOrderItemType = option as orderItemType | preItemType;
  if (asOrderItemType.selectedCustomization) {
    customization = asOrderItemType.selectedCustomization;
  }

  if (!customization) return customizationAmount;

  customization.anyOf?.choices.forEach((choice, i) => {
    if (!choice.value || !choice.price) return;
    const amt = choice.price * choice.value;
    customizationAmount += amt;
  });

  customization.oneOfList?.forEach((oneOfItem, i) => {
    oneOfItem.choices.forEach((choice, idx) => {
      if (!choice.value || !choice.price) return;
      const amt = choice.price * choice.value;
      customizationAmount += amt;
    });
  });

  // const itemPrice = price ?? option.price;
  return customizationAmount;
};

const formatterCAD = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const floorPrice = (price: number) => {
  const flooredVal = Math.floor(Math.abs(price));
  return price < 0 ? flooredVal * -1 : flooredVal;
};

export const floorPriceAndDivideByHundred = (price) => {
  return floorPrice(price) / 100;
}

export const formatPrice = (price?: number) => {
  if (price === undefined || price === null) return "";

  const flooredPrice = floorPriceAndDivideByHundred(price);
  const formattedVal = formatterCAD.format(flooredPrice);
  return formattedVal;
}

export const calculateDiscountAmt = (discount: BusinessDiscount | null, subtotal: number) => {

  if (discount?.value === undefined) return 0;

  const isOverMaxPurchase = !!discount.maxPurchase && discount.maxPurchase < subtotal;
  if (discount.type === DISCOUNT_VALUE_TYPE.percentage) {
    const stotal = isOverMaxPurchase ? discount.maxPurchase! : subtotal;
    const percentVal = stotal * 0.01 * discount.value;
    return floorPrice(percentVal);
  } else if (discount.type === DISCOUNT_VALUE_TYPE.amount) {
    return discount.value;
  }

  return 0;
}

export const calculateMaxPurchaseAmt = (discount: BusinessDiscount | null) => {
  if (!discount) return 0;

  if (discount.maxPurchase === undefined) return 0;

  if (discount.type === DISCOUNT_VALUE_TYPE.percentage) {
    const percentVal = discount.maxPurchase * 0.01 * discount.value;
    return floorPrice(percentVal);
  } else if (discount.type === DISCOUNT_VALUE_TYPE.amount) {
    return discount.value;
  }

  return 0;
}

export const calculateTip = (finalOrder: FinalOrderPayload) => {
  const selectedTipPercent = finalOrder.selectedTipPercentage ?? 0; // 10 0 15
  const subTotalWithSelectedTip = finalOrder.subTotal * selectedTipPercent; // 1000 * 10 = 10000
  const flooredCalculatedTip = floorPriceAndDivideByHundred(subTotalWithSelectedTip);

  return flooredCalculatedTip;
}
