import axios from "axios";
import { StoreOrderingState } from "../constants";
import { baseUrl } from "../serverConfig";
import { trackOrderPayload, receiptPayload, OrderStatus } from '../Types/Types';

export const CancelOrder = async (orderId, serviceProviderUserId) => {
  // console.log("CANCEL ORDER: ", orderId, serviceProviderUserId)
  await axios.get(`${baseUrl}/rest/order/cancel/${orderId}/${serviceProviderUserId}`)
}

export const CancelNewOrder = async (orderId, businessId) => {
  return await axios.get(`${baseUrl}/rest/orderAction/cancelNewOrder/${orderId}/${businessId}`)
}

// export const ValidatePayment = async(body) => {
//   const paymentResponse = await fetch("http://localhost:8080/payment", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//     body,
//   });
//   // const paymentResponse = axios.post('http://localhost:8080/payment', body)
//   console.log("API: ", paymentResponse)

//   return paymentResponse
// }

export const GetBusinessHours = async (businessName: string) => {
  const { data } = await axios.get(`${baseUrl}/rest/business/${businessName}`)
  return data
}

export const PutFeedback = async (payload) => {
  const { data } = await axios.put(`${baseUrl}/rest/rating/feedback`, payload)
  return data;
}

export const SubmitToServerIAmHere = async (orderId) => {
  return await axios.get(`${baseUrl}/rest/orderAction/imHere/${orderId}`)
}

export interface LegacyBusinessInfo {
  ratings: {
    averageUserScore: number;
    overallSystemScore: number;
    ratingsId: number;
    socialPopularity: number;
    socialVisibility: number;
  };
  addressList: {
    googleMapUri: string;
  }[];
  frequentlyPhoneNumber: string
}
export const GetBusinessInfo = async (storeId): Promise<LegacyBusinessInfo> => {
  try {
    const { data } = await axios.get(`${baseUrl}/rest/link/biz/userId/${storeId}`)
    return data
  } catch (err) {
    return err.message;
  }
}


export const RequestStatus = async (orderNumber: string) => {
  try {
    const url = `${baseUrl}/rest/orderAction/orderStatus`;
    const res = await axios.get(`${url}/${orderNumber}`);
    const { data } = res;
    const currentOrderPayload = data.orderPayload;

    const payloadParsed = JSON.parse(currentOrderPayload);
    const newFinalOrderItems = payloadParsed.finalOrderItems.map(item => {
      item.price = +item.price
      return item
    });

    const newOrderPayload = { ...JSON.parse(currentOrderPayload), finalOrderItems: [...newFinalOrderItems] }

    // return data;
    return { ...data, orderPayload: JSON.stringify(newOrderPayload) } as OrderStatus;
  } catch (err) {
    if (err instanceof Error) {
      return {
        message: `Things exploded (${err.message})`,
      };
    }
  }
}

export const PostOrder = async (order: trackOrderPayload) => {
  try {
    const { data } = await axios.put(`${baseUrl}/rest/order`, order);
    // const { data } = await axios.put(`http://localhost:8080/post-order`, order);
    console.log("-----ORDER PAYLOAD: ", order)
    // must hook to redux later 
    return data;

  } catch (err) {
    return err.message
  }
}

export const SendReceipt = async (receiptPayload: receiptPayload) => {
  try {
    const { data } = await axios.put(`${baseUrl}/rest/order/receipt`, receiptPayload);
    // must hook to redux later
    return data.success;
  } catch (err) {
    return err.message
  }

}